import { useState } from "react";
import Usetrite from "./Usetrite";

function Kosik({kosik,vse,setViewKosik}) {
    var polozky = null;
    if (kosik !== undefined) {
    polozky = kosik.polozky.map((item,index) =>{
        if (item.row_qnt > 0 && !vse) {
        if (item.row_qnt > 0 && index < 3) return <tr key={index} className='border-t' >
                  <td>{item.product_name}</td>
                  <td align='right' className='pl-2'><b>{item.row_qnt}</b></td>
               </tr>
        }       
        else return <tr key={index} className='border-t' >
        <td>{item.product_name}</td>
        <td align='right' className='pl-2'><b>{item.row_qnt}</b></td>
     </tr>

    });
    }

    return (
        <div className='w-full h-full relative p-1 bg-green-100'>
            <div className='text-[1em] text-center w-full font-bold'>
                Košík
                { !vse ? 
                    <div className='text-sm font-normal'>poslední 3 přidané</div> 
                :   <svg onClick={(e) => setViewKosik('translateX(-100%)')} className="float-right m-2 w-4 h-4 hover:text-white text-black inline-block" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m13 7-6 6m0-6 6 6m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                    </svg> 
                }
            </div>
            { vse ?
            <div className='w-full pt-2 text-center text-[#328b61]'>
                <Usetrite kosik={kosik} />
            </div>
            : null }
            <div className='h-full relative overflow-y-auto mb-2'>
                <table className='w-full'>
                    <tbody>
                    {polozky}
                </tbody>                
                </table>
              
            </div>
            { !vse ?
            <div className='w-full absolute bottom-[58px] text-center text-[#328b61] mt-1'>
                <Usetrite kosik={kosik} />&nbsp;
            </div>
            : null }
        </div>
    )
}

export default Kosik;