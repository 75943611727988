
    import { useEffect, useState } from 'react';
import { getUrlApi, jeCisloSude, postData } from '../../../functions';
    import { nastavitDrobecky } from './DrobeckyNav';
import { AuthData } from '../../../auth/AuthWrapper';
import { useNavigate } from 'react-router-dom';
    //min-h-[calc(100vh-42px)]
    
    function HlavniNabidka({navbarHeight,setZobrazeni,setDrobecky}) {
      const {nastaveni,bombaCeny} = AuthData();
      const navigate = useNavigate();

      //console.log('NASATTCEVENI',bombaCeny,nastaveni);
      const getIcon = (nazev) => {
        switch (nazev) {
            case 'shopping-bag': 
        
                return <svg className="w-12 h-12 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                <path d="M17 5.923A1 1 0 0 0 16 5h-3V4a4 4 0 1 0-8 0v1H2a1 1 0 0 0-1 .923L.086 17.846A2 2 0 0 0 2.08 20h13.84a2 2 0 0 0 1.994-2.153L17 5.923ZM7 9a1 1 0 0 1-2 0V7h2v2Zm0-5a2 2 0 1 1 4 0v1H7V4Zm6 5a1 1 0 1 1-2 0V7h2v2Z"/>
              </svg>
            case 'rectangle-list': 
        
            return  <svg className="w-12 h-12 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 16">
                         <path d="M18 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2ZM5 12a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm0-3a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm0-3a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm10 6H9a1 1 0 0 1 0-2h6a1 1 0 0 1 0 2Zm0-3H9a1 1 0 0 1 0-2h6a1 1 0 1 1 0 2Zm0-3H9a1 1 0 0 1 0-2h6a1 1 0 1 1 0 2Z"/>
                    </svg>

            case 'newspaper': 
        
                return <svg className="w-12 h-12 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M19 4h-1a1 1 0 1 0 0 2v11a1 1 0 0 1-2 0V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v15a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V5a1 1 0 0 0-1-1ZM3 4a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4Zm9 13H4a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-3H4a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-3H4a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-3h-2a1 1 0 0 1 0-2h2a1 1 0 1 1 0 2Zm0-3h-2a1 1 0 0 1 0-2h2a1 1 0 1 1 0 2Z"/>
                            <path d="M6 5H5v1h1V5Z"/>
                        </svg>

            case 'giftbox': 
        
                return <svg className="w-12 h-12 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M18 5h-.7c.229-.467.349-.98.351-1.5a3.5 3.5 0 0 0-3.5-3.5c-1.717 0-3.215 1.2-4.331 2.481C8.4.842 6.949 0 5.5 0A3.5 3.5 0 0 0 2 3.5c.003.52.123 1.033.351 1.5H2a2 2 0 0 0-2 2v3a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V7a2 2 0 0 0-2-2ZM8.058 5H5.5a1.5 1.5 0 0 1 0-3c.9 0 2 .754 3.092 2.122-.219.337-.392.635-.534.878Zm6.1 0h-3.742c.933-1.368 2.371-3 3.739-3a1.5 1.5 0 0 1 0 3h.003ZM11 13H9v7h2v-7Zm-4 0H2v5a2 2 0 0 0 2 2h3v-7Zm6 0v7h3a2 2 0 0 0 2-2v-5h-5Z"/>
                        </svg>
            default:           
        }               
      }

      const handleClickAkce = (item) => {
        
        if (item.klic !== null) {
          
          //navigate(item.klic.toLowerCase() );
          setZobrazeni(item.klic);
          nastavitDrobecky(item.klic,setDrobecky);
        }  
        else {
          if (item.file !== null && item.file !== '') {
            //otevrit v novem okne prilozeny soubor
            window.open('/pdf/'+item.file, '_blank', 'location=no,scrollbars=yes,status=yes');
          }
        }
      }

      let menu = []; 
      if (nastaveni.config['competition_file'] !== '') 
         menu =[{id: 10,
                 akce: 'Losování',klic: null ,
                 file: nastaveni.config['competition_file'],
                 icon: 'giftbox'},...menu];

      if (nastaveni.config['reward_file'] !== '') 
      menu =[{id: 6,
              akce: 'Odměny za Vaší objednávku',klic: null ,
              file: nastaveni.config['reward_file'],
              icon: 'giftbox'},...menu];
              
                         
      if (nastaveni.config['letaky_file'] !== undefined && nastaveni.config['letaky_file'] !== '')
         menu =[{id: 2,akce: 'Letáky',file: nastaveni.config['letaky_file'],klic: null, icon: 'newspaper'},...menu]; 
      
      menu =[{id: 3,akce: 'Moje objednávka',klic: 'MOJEOBJEDNAVKA',icon: 'rectangle-list'},...menu];
      menu =[{id: 4,akce: 'Objednávkové listy',klic: 'OBJEDNAVKOVELISTY',icon: 'shopping-bag'},...menu];
      
      if (bombaCeny != null && bombaCeny.polozky !== null && bombaCeny.polozky.length > 0) 
         menu =[{id: 5,
                 akce: 'Bomba ceny',klic: 'BOMBACENY',
                 icon: 'shopping-bag'},...menu];

      if (nastaveni.config['bonus_file'] !== '') 
         menu =[{id: 8,
                 akce: 'Speciál',klic: null,
                 file: nastaveni.config['bonus_file'],
                 icon: 'shopping-bag'},...menu];
      
/*         TODO   */     
         menu =[{id: 7,
                 akce: 'Mapa',klic: 'MAPA',
                 icon: 'newspaper'},...menu];    
                 

      var customClass = 'h-[calc(100vh-56px)] grid grid-cols-2 max-sm:grid-cols-1 gap-0';
      //console.log(customClass);
    
      var pocetSudy  = jeCisloSude(menu.length);
      var colspan = 'sm:col-span-1';
      const listItems = menu.map((item,index) =>{
            colspan = 'sm:col-span-1';
            if (pocetSudy === false && index === menu.length-1) colspan = 'sm:col-span-2';

            return <div key={item.id} onClick={(e) =>handleClickAkce(item)} 
                                className={`${colspan} text-[#ebe7e7] relative hover:text-black opacity-80 rounded bg-[#8f8f8f] hover:bg-blue-300 m-1 max-sm:grid-cols-1 grid  place-items-center shadow-inner cursor-pointer`}>
                            <div className='text-center mt-3'>
                            {getIcon(item.icon)}
                            </div>
                        <div className='w-full text-center font-bold text-2xl relative m-0'>

                        {item.akce}
                        
                        </div>
                    </div>
         }
      );
    
      return (
         <div className={customClass}>
              {listItems}
    </div>
      )
    }

export default HlavniNabidka;   