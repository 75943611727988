import { useEffect, useState } from "react";
import { getUrlApi, postData,formatNum,pointsText, formatCena} from "../../../functions";
import { AuthData } from "../../../auth/AuthWrapper";
import Klavesnicka from "./components/Klavesnicka";
import Tlacitko from "./components/Tlacitko";
import MyDialog from "./components/MyDialog";

function MojeObjednavka({setZobrazeni,objednaciDny,setZprava}) {
    const {kosik,setKosik,nastaveni,setDodavatel} = AuthData();
    const [dataKlavesnicka,setDataKlavesnicka] = useState(null);
    const [viewKlavesnicka,setViewKlavesnicka] = useState(false);  
    const [polKeSmazani,setPolKeSmazani] = useState(null);
    const [isSrolling,setIsScroolling] = useState(false);

    var dodavatelNazev = '';
    //vyskrolovani je při rerenderu
    useEffect(() => {
        window.addEventListener("scroll", getPositionTop);
        //console.log('registruji eventu sroll');

        window.scrollTo({ top: 0, behavior: 'smooth' });

        return () => { window.removeEventListener("scroll", getPositionTop);

                    }
     },[]);


    const odstranitzKosiku = (polozka) => {
        postData(getUrlApi(),{akce: 'deleteFromBasket',productId: polozka.product_id }).then(result => {
            if (result && result['status'] === 'OK') {
                setKosik(result['kosik']);
            }
        });
    }

    const pokracovatkOdmenam = () => {
        postData(getUrlApi(),{akce: 'mojeObjednavka'}).then(result => {
            if (result && result['status'] === 'OK') {
                if (result['vysledek'] === null ) setZobrazeni('ODMENYVYBER');
                else {
                    setDodavatel(nastaveni.dodavatele.filter(dod =>dod.id === result['vysledek'].supplier_id)[0]);
                    setZprava(result['vysledek'].Title+'\n\n'+result['vysledek'].message1+'\n\n'+result['vysledek'].message2);
                    setZobrazeni('OBJEDNAVKOVELISTY');
                }
            }
        });
        
    }
 
     
    const aktivovatKlavesnicku = (data) => {
        setDataKlavesnicka(data);
        setViewKlavesnicka(true);
    }

    //hlida jak moc uzivatel scroluje
    //stejna i OdmenyVyber
    const getPositionTop = () => {
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop
        //setY(y);
        //console.log('contionousPosition',document.documentElement.scrollHeight,document.documentElement.clientWidth,winScroll);
        var hranice = 60;
        if (document.documentElement.clientWidth<640) hranice=120;
        if (winScroll > hranice) setIsScroolling(true); else setIsScroolling(false);
      };

    return (
        <>
        <MyDialog polozka={polKeSmazani} akcePotvrdit={odstranitzKosiku}/>
        <div className='w-full h-dvh p-6 overflow-x-auto'>

            <Klavesnicka dataKlavesnicka={dataKlavesnicka}
                         viewKlavesnicka={viewKlavesnicka}
                         setViewKlavesnicka={setViewKlavesnicka}
                         setZprava={setZprava} />

            <div className='m-1 px-4 inline float-left'>
                        <Tlacitko popis='Zpět' barva='green' 
                                akceOnClick={() =>{setZobrazeni('OBJEDNAVKOVELISTY');}} />
            </div> 

            <div className='text-2xl font-bold m-2 mr-3 float-left'>Moje objednávka</div> 
            <div className='m-1 px-4 inline float-left'>
                        <Tlacitko popis='Dolů' barva='green' 
                                akceOnClick={() =>{window.scrollTo({ top: 9999, behavior: 'smooth' });}} />
            </div> 
        {kosik !== undefined && kosik.polozky.length > 0 ? 
            <>
            <table className="w-full relative">
                <div className={(isSrolling ? "block" : "hidden")+" fixed top-[56px] p-2 bg-white w-full z-50"}>
                    <div className="flex content-center bg-gray-100">
                        <div className="font-bold flex-none pl-1 p-3">Týden</div>
                        {objednaciDny !== undefined && objednaciDny.map((row,index) =>{
                            return <div align='center' className="pl-4 w-[76px]">{row.Dscr.split('-')[0]}<br />{row.Dscr.split('-')[1]}</div>
                        })
                        }
                        <div  align='center' className="font-bold flex-auto p-3">Celkem</div>
                        <div className="flex-auto min-w-[65px]"  ></div>
                        <div className="flex-auto" >
                            <button type="button" 
                                     
                                      className="hidden mt-4 mr-2 text-white bg-red-500 hover:bg-red-800  font-medium rounded text-sm px-5 py-2.5 text-center"
                                    >Odstranit</button> 
                        </div>
                    </div>
 
            </div> 
            
                <tr className="bg-gray-100">
                    <td className="font-bold">Týden</td>
                    {objednaciDny !== undefined && objednaciDny.map((row,index) =>{
                        return <td align='center' className="pl-4">{row.Dscr.split('-')[0]}<br />{row.Dscr.split('-')[1]}</td>
                    })
                    }
                    <td  align='center' className="font-bold">Celkem</td>
                    <td></td>
                    <td></td>
                </tr>
                { kosik !== undefined && kosik.polozky.length > 0 && kosik.polozky.map((row,index) => {
                    if ((kosik.polozky[index-1] === undefined) || (kosik.polozky[index-1] !== undefined && row.supplier !== kosik.polozky[index-1].supplier)) {
                        dodavatelNazev = kosik.polozky[index].supplier;
                    } else dodavatelNazev = '';
                    return <>
                            {dodavatelNazev && <tr className="bg-gray-500 text-white m-1"><td colSpan={objednaciDny !== undefined ? objednaciDny.length+4 : 1}>{dodavatelNazev}</td></tr> }
                            <tr className="">
                              <td className=" text-right pr-2">{row.product_no}</td>
                              <td className="font-bold" colSpan={objednaciDny !== undefined ? objednaciDny.length : 1}>{row.product_name}</td>
                              {row.row_qnt > 0 && objednaciDny !== undefined && objednaciDny.map((obj,index) =>{
                                    return <td>
                                        
                                           </td>
                                })
                              }
                              <td className='text-gray-400 pl-[1px] text-[0.9em] px-1'>
                              {formatNum(row.pcs)} {row.unit}
                                                <br />
                                                <div className=" text-nowrap whitespace-nowrap">{pointsText(row.points)}</div>
                                                <div className=" text-nowrap whitespace-nowrap text-red-600">{formatCena(row.promo_price)}</div>
                              </td>
                              <td>                                
                              <button type="button" 
                                      onClick={()=>{setPolKeSmazani(row)}}
                                      className="mt-0 mr-2 text-white bg-red-500 hover:bg-red-800  font-medium rounded text-sm px-5 py-2.5 text-center"
                                    >Odstranit</button> 
                              </td>
                                                      
                           </tr>
                           <tr className="">
                              <td></td>
                               {row.row_qnt > 0 && objednaciDny !== undefined && objednaciDny.map((obj,index) =>{
                                    return <td align='center'>
                                             <input className={"max-w-[3.5em] p-0 text-center "+(row['available_'+obj.ord] === 0 || obj.active === 0 ? 'bg-gray-400' : 'rounded') }
                                             type={row['available_'+obj.ord] === 1  && obj.active === 1 ? "number" : "text" }
                                             value={formatNum(row['week'+obj.ord])}
                                             disabled={row['available_'+obj.ord] === 0 || obj.active === 0 ? true : false}
                                             onClick={(e)=>{row['available_'+obj.ord] === 1  && obj.active === 1 && aktivovatKlavesnicku({mnoz: e.target.value,productId: row.product_id,weekOrd: obj.ord}) } } />
                                           </td>
                                })
                              }
                              <td align='center' className="font-bold">{formatNum(row.row_qnt)}</td>                             
                           </tr>   
                           <br/>            
                           </>
                    })

                }         
                 <tr className="h-10"><td >&nbsp;</td></tr>
                 <tr className="border-t-0 bg-gray-100">
                    <td className=" font-bold border-r border-gray-300">Týdně</td>
                    {objednaciDny !== undefined && objednaciDny.map((obj,index) =>{
                        return <td align='center' className="border-r border-gray-300">{formatCena(kosik.sumy['week'+obj.ord])}</td>
                    })
                    }
                    <td></td> 
                    <td></td> 
                                              
                </tr>
                <tr className=" text-green-500 font-bold bg-green-100">
                    <td className="border-r border-gray-300">Ušetříte</td>
                    {objednaciDny !== undefined && objednaciDny.map((obj,index) =>{
                        return <td align='center' className="border-r border-gray-300">{formatCena(kosik.usetrite['week'+obj.ord])}</td>
                    })
                    }
                    <td colSpan={1}  className="text-green-600 font-bold"></td>                             
                    <td></td>
                </tr>                            
                          
            </table>
            <div  className="mt-4 text-green-600 text-xl float-left">Celkem ušetříte: <div className="font-bold inline">{formatCena(kosik.totalUsetrite)}</div></div>
            </>
            :
            <>
                <div className="w-[600px] relative  float-left">     
                    <div className="pl-10 pt-[100px]  float-left">
                        <div className="w-20 h-20 rounded-full text-center pt-5 bg-blue-500 text-white text-3xl ">i</div>
                    </div>
                    <div className="m-2 pt-[120px] float-left w-[250px] text-xl">Zatím nemáte nic v košíku</div>
                </div>
                <div className="clear-both"></div>
            </>
            }
          
                <div className="relative float-left w-[240px] pt-1">
                    {kosik !== undefined && kosik.polozky.length > 0 &&
                      <>
                        <div className='m-1 inline float-right'>
                        <Tlacitko popis='Vybrat odměny' 
                                barva='green' 
                                akceOnClick={() =>{pokracovatkOdmenam()}} />
                        </div>
                        
                        <div className='m-1 px-4 inline float-right'>
                            <Tlacitko popis='Zpět' barva='green' 
                                    akceOnClick={() =>{setZobrazeni('OBJEDNAVKOVELISTY');}} />
                        </div>
                      </>
                    }

                </div>




        </div>        

        </>

    )
}

export default MojeObjednavka;