import { useEffect, useState } from "react";
import { formatNum, getUrlApi, postData,isIOS } from "../../../functions";
import { AuthData } from "../../../auth/AuthWrapper";
import Message from "./components/Message";


function Objednat({polozka, setAktivniPolozka,setZahlaviStatus}) {
   const {kosik,setKosik,dodavatel,setInfoLosy} = AuthData();
   const [toggle,setToggle] = useState('hidden');
   const [objednat,setObjednat] = useState(null);
   const [viewMessage,setViewMessage] = useState(false);
   const [objednaciDny,setObjednaciDny] = useState(null);
   const [msg,setMsg ] = useState(null);
   let info = {ks: 'PRODUKT SE OBJEDNÁVÁ PO CELÝCH KARTONECH',
               kg: 'PRODUKT SE OBJEDNÁVÁ V KILOGRAMECH'

             }

   let kObjednani = [];

    //načtení objednávacích/závozových týdnů vždy, když otevře
    useEffect(() => {
      
      if (polozka !== undefined && polozka !== null) {
      postData(getUrlApi(),{ akce: 'getWeeks'}).then(result => {
         if (result !== undefined && result.status === 'OK') {
            setObjednaciDny(result['weeks']);
      
         var indPol = kosik.polozky.findIndex(item => polozka.id === item.product_id);
         //console.log('POLOZKA',polozka,indPol,kosik);
         if (indPol > -1 && kosik.polozky[indPol] !== undefined) {
            
            var pom = JSON.parse(JSON.stringify(result['weeks']));
            pom = pom.map(item =>{
               
               if (kosik.polozky[indPol]['week'+item.ord] != null) {
                  
                  item['mnoz'] = kosik.polozky[indPol]['week'+item.ord];
                  
                  if (polozka.unit == 'ks') item['mnoz'] = formatNum(item['mnoz']);
               }   
               return item;
            });
            //console.log('kosikObjednat',pom,kosik.polozky[indPol]);
            setObjednat(pom);
         }   
         else {
            pom = result['weeks'].map(item => {
                 // console.log('OBBBJEdnat',item);
               if (item.mnoz === undefined) item.mnoz = 0;
               return item; 
            });
            setObjednat(pom);
         }   
         //console.log('Objednat',polozka,kosik,indPol,objednaciDny);
         
         setToggle(''); 
         
      
     }
    });
   } else setToggle('hidden');
   
    },[polozka]);


    //heck zakazani posunu pozadi
    useEffect(() => {
      if (toggle !== 'hidden') {
        document.body.style.overflow = 'hidden';
        document.body.style.paddingRight = '15px';
      }
      return () => {
        document.body.style.overflow = 'unset';
        document.body.style.paddingRight = '0px';
      };
    }, [toggle]);



    const handleChange = (index,mnoz) => {
      //console.log('pridatDoObj',index,mnoz,objednaciDny);

      setMsg(null);
      setViewMessage(false);
      objednat[index].mnoz = mnoz;
      setObjednat([...objednat]);

    }

    const odstranitPole = (obj,arrToDel)=>{ 
         let newObj = [...obj];  
         newObj.map((iObj,index) =>{
               arrToDel.map(item =>{
                  delete(iObj[item]);
               });
      });
      return newObj;
    }

    const ObjednatAkce = (e) =>{
      var data = null;
      var pomPol = {...polozka};
      var test = kosik.polozky.findIndex(item => pomPol.id === item.id);
      //console.log('objednatakce',test,kosik,pomPol);

      var status = true;
      //sum weeks 
      var sumPolozky = 0;
      objednat.forEach(item =>{
         if (item.mnoz >= 0) sumPolozky += item.mnoz;
         else {
            setMsg('Není možné objednat záporné množství');
            //console.log('msg','Není možné objednat záporné množství');
            setViewMessage(true);
            status = false;
            e.PreventDefault();
         }
      });
      //console.log('sumPolozky',msg,objednat,sumPolozky);
   
      if (status && sumPolozky === 0) {
         setMsg('Není možné objednat nulové množství');
         setViewMessage(true);

      }  
      else {
            
            pomPol.row_qnt = sumPolozky;

            if (kosik.polozky[test] && kosik.polozky[test].objednat !== null) {
               let pom,mnoz = null;
               objednat.map((item,index) =>{
                  pom = {...item};
                  if (mnoz !== 0) kObjednani.push(pom);
               });
              // console.log('kObjednani',kObjednani);
            } else kObjednani = objednat;

            pomPol.objednat = JSON.parse(JSON.stringify(objednat));
            data = {'akce'          :  'objednat' ,
                     'pID'          : pomPol.id,
                     'objednaciDny' : kObjednani
                  };
            
            
            postData(getUrlApi(),data).then(result =>{
               if (result !== undefined) {
                  setZahlaviStatus(1);
                  if (result.status === 'OK') {
                     setToggle('hidden');
                     setAktivniPolozka(null);
                     setInfoLosy(result.losy);
                     setKosik(result.kosik);   
                     setViewMessage(false);                  
                  } else {
                     //console.log('RESULT else',result);
                     setViewMessage(false);
                     setMsg(result.vysledek);
                     setViewMessage(true);
                  } 
               } else   setZahlaviStatus(0);
               
            });
        }

  };
  
  const handleFocus = (event) => event.target.select();

    return (
      <div className={toggle+" pl-1 pr-3 backdrop-contrast-[25%] overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full  h-[calc(100%)] max-h-full"}>
    
                <div className='max-md:mt-[6vh] mt-[25vh] max-md:w-full max-w-[1000px] bg-white min-h-[100px] rounded border-2 text-center mx-auto '>
                     <div className=' text-center mt-3 ml-1 mr-1 pb-3'>
                     {polozka !== null && polozka.unit === 'ks' ? 
                              <div className=' text-green w-full  text-center uppercase  text-green-500 border-[2px]'>{info.ks}</div> 
                              : 
                              <div className=' text-red w-full text-red-500 text-center uppercase  border-2 border-red-500  text-red-60'>{info.kg}</div> 
                        }
                     <div className='relative text-2xl  text-center bg-yellow-200 w-full pb-5'>
                        <div className="float-right text-xl p-0 m-0 text-gray-600">{polozka !== null ? polozka.product_no : null}</div>

                        <div className="pt-2 pl-4 pr-4">{polozka !== null ? polozka.product_name : null}</div>
                        <div className="float-right p-0 m-0 text-gray-600 text-sm">{polozka !== null && polozka.unit === 'ks' ? formatNum(polozka.pcs)+' '+polozka.unit+' v kartonu, minimum '+Math.round(polozka.min_pcs)+' '+' krt.'     :     polozka !== null && polozka.unit === 'kg' ? 'minimum '+polozka.min_pcs+' '+polozka.unit : null }</div>
                     </div>
                  </div>


                     <form  className="max-md:hidden"> 
                        <div className="w-full p-2 relative  ml-auto mr-auto">
                           <table className="p-2 relative w-full mx-auto">        
                              <tbody>
                                 <tr>
                                 {polozka !== null && objednat !== undefined && objednat !== null && objednat.map((row,index) => {                                
                                       return <td className="text-center">{objednaciDny[index].Dscr.split(' - ')[0]}<br/>{objednaciDny[index].Dscr.split(' - ')[1]}</td>
                                    })
                                 }  
                                 </tr>
                                 <tr>            
                                 {polozka !== null && objednat !== undefined && objednat !== null && objednat.map((row,index) => {                                

                                    return <>                                             
                                             <td className="relative ">
                                       
                                                <input className={(polozka['available_'+row.ord] === 0 || row.active === 0 ? 'bg-gray-400' : '')+" w-full text-center p-1"} 
                                                                  type={!isIOS ? 'number' : 'text'}
                                                                  onChange={(e)=>{handleChange(index,e.target.value)}} 
                                                                  value={row.mnoz}
                                                                  min='0'
                                                                  step={polozka.min_pcs} 
                                                                  disabled={polozka['available_'+row.ord] === 0 || row.active === 0  ? true : false}
                                                                  onClick={handleFocus} /></td>
                                             </>                              
                                            })
                                 
                                 }
                                 </tr>
                              </tbody>
                           </table>
                        </div>
                     </form>

                     <form className="md:hidden">
                        <div className="w-[100%] p-2 relative  ml-auto mr-auto">
                           <table className="w-[220px] p-2 relative  ml-auto mr-auto">        
                              <tbody>
                                 <td colSpan='2' align='center'>

                                 </td>
                                 {polozka !== null && objednat !== undefined && objednat !== null ? objednat.map((row,index) => {

          
                           return (<tr key={index}>
                                    <td className="text-left font-bold">{objednaciDny[index].Dscr}</td>
                                    <td><input className={"p-0 w-[100px] text-center text-[1.2em] "+(polozka['available_'+row.ord] === 0 && 'bg-gray-200')} 
                                                type='number'
                                                onChange={(e)=>{handleChange(index,e.target.value)}} 
                                                value={row.mnoz}
                                                min='0'
                                                step={polozka.min_pcs} 
                                                disabled={polozka['available_'+row.ord] === 0 ? true : false}
                                                onFocus={handleFocus} /></td>
                                 </tr>)
                                 })
                                 : null
                              }
                              </tbody>
                           </table>
                        </div>
                     </form>
                     <div>
                        <Message text={msg} 
                                 setViewMessage={setViewMessage}
                                 viewMessage={viewMessage} />
                     </div>
                     <button
                        type="button" 
                        onClick={(e)=>{setToggle('hidden');setAktivniPolozka(null);setViewMessage(false)}}
                        className="float-left mb-5 mx-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                     >Zpět</button>                     
                     <button
                        type="button" 
                        onClick={ObjednatAkce}
                        className="float-right mb-5 mx-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                     >Potvrdit</button>
                     <br />
                     <br />
                </div>
                    
                 

           </div>        
    )
}

export default Objednat;