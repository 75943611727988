import { nastavitDrobecky } from "./DrobeckyNav";

function DetailProduktu({setDetailProduktu,  setViewDetailObrazky, polozka, dodavatel,objednat,setDrobecky}) {
    
   

    //vyskrolovani nahoru
    window.scrollTo({ top: 0, behavior: 'smooth' });
     return (
        <div className='relative w-full'>
            <button type="button" 
                    className='text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-1 focus:ring-gray-200 rounded-sm text-sm px-5 pt-1.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700'
                    onClick={(e) => {nastavitDrobecky('OBJEDNAVKOVELISTY',setDrobecky);setDetailProduktu(null)}}>
                <svg className="w-3 h-2.5 float-left m-1.5 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 8 14">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 1 1.3 6.326a.91.91 0 0 0 0 1.348L7 13"/>
                </svg> 
                Zpět
            </button>
            <div className='relative w-full bg-white border rounded-sm min-h-[60vh]'>
                <div onClick={(e) =>{setViewDetailObrazky(polozka.product_no)}} className=' w-[200px] xl:float-left p-3 relative  ml-auto mr-auto'>
                    <img  className='object-cover xl:absolute xl:left-[35%] max-lg:relative max-h-[15em] ml-5' 
                          src={'https://obrazky.alimpexfood.cz/DATA_x800/'+polozka.product_no+'.png'}
                          alt='' />
                </div>
                <div className='w-full xl:w-[450px] float-right p-2 relative'>
                    <div className='w-full mb-1 text-xl text-left font-bold'>{polozka != null ? polozka.product_name : null}</div> 
                    <div className='w-full border-b-gray-200 border' />
                    <div className='w-full grid grid-cols-2 place-items-stretch'>
                    
                        <div>Dodavatel:</div><div className='text-right font-bold'>{dodavatel.supplier}</div>
                        <div>Skupina:</div><div className='text-right'>Chlazené</div>
                        <div>Počet jednotek v kartonu:</div><div className='text-right'>14 ks</div>
                        <div>Minimální objednávka:</div><div className='text-right'>14 ks</div>
                        <div>Kód:</div><div className='text-right font-bold'>{polozka != null ? polozka.product_no : null}</div>
                    
                    </div>
                    <div className='w-full border-b-gray-200 border' />
                    <button
                        type="button" 
                        onClick={(e) => {setDetailProduktu(null);console.log('clickDetailObjendat');objednat(polozka)}}
                        className="float-right mt-5 ml-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                     >Objednat</button>
                </div>
            </div>
        </div>
    )
}

export default DetailProduktu;