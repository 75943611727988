import { useEffect, useState } from "react";
import { getUrlApi, postData,formatNum, formatCena,pointsText } from "../../../functions";
import { AuthData } from "../../../auth/AuthWrapper";
import { useNavigate } from "react-router-dom";
import Tlacitko from "./components/Tlacitko";

function Rekapitulace({setZobrazeni,objednaciDny,setZprava}) {

    const navigate = useNavigate();

    const {kosik,setKosik,setUser,logout} = AuthData();
    const [data,setData] = useState(kosik);
    const [promo,setPromo] = useState([]);
    const [odmeny,setOdmeny] = useState([]);
    const [isSrolling,setIsScroolling] = useState(false);
    const [losyCelkem, setLosyCelkem] = useState('');

    var dodavatelNazev = '';
    var timeout = null;
    useEffect( () => {
        window.addEventListener("scroll", getPositionTop);
        //console.log('registruji eventu sroll');

        window.scrollTo({ top: 0, behavior: 'smooth' });

        postData(getUrlApi(),{akce: 'rekapitulace'}).then(result => {
                if (result && result.status === 'OK') {
                    setPromo(result['userPromo']);
                    setOdmeny(result['userOdmeny']);
                    setLosyCelkem(result['losyCelkem']);
                }    
        });
        return () => {
                        //console.log('smzycka intrval yastavena');
                        window.removeEventListener("scroll", getPositionTop);
                        clearTimeout(timeout);
                    }
    },[]);


    //hlida jak moc uzivatel scroluje
    //stejna i OdmenyVyber,MojeObjednavka
    const getPositionTop = () => {
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop
        //setY(y);
        //console.log('contionousPosition',document.documentElement.scrollHeight,document.documentElement.clientWidth,winScroll);
        var hranice = 60;
        if (document.documentElement.clientWidth<640) hranice=120;
        //VYPNUTO
        //if (winScroll > hranice) setIsScroolling(true); else setIsScroolling(false);
      };

    const potvrditObjednavku = (e) => {
        postData(getUrlApi(),{akce:'potvrditObjednavku'}).then(result =>{
            if (result && result.status === 'OK') {
                //setNastaveni({...result.nastaveni});
                setKosik([]);
                setData(null);
                setZprava('Děkujeme za Vaši objednávku, Váš Alimpex');
                timeout = setTimeout(() => {logout();navigate('/');},2000);
                
            }

        });
        //console.log('potvrditObjednavku',nastaveni.odmeny);
        //setZobrazeni('HLAVNINABIDKA');
    }

    return (

    <div className='w-full h-dvh overflow-x-auto'>
        <div className='text-2xl font-bold m-2 float-left'>Rekapitulace</div>
        <div className='m-1 px-4 inline float-left'>
                        <Tlacitko popis='Dolů' barva='green' 
                                akceOnClick={() =>{window.scrollTo({ top: 9999, behavior: 'smooth' });}} />
            </div> 
        {data !== null && 
            <table className="w-full m-1">
                <tr className="bg-gray-100 border-b border-black">
                    <td className="font-bold border-r border-black">Týden</td>
                    {objednaciDny !== undefined && objednaciDny.map((row,index) =>{
                        return <td align='center' className="pl-1 border-l border-gray-300 font-bold">{row.Dscr.split('-')[0]}<br />{row.Dscr.split('-')[1]}</td>
                    })
                    }
                    <td align='center' className="border-l border-black" >Celkem</td>
                    <td></td>
                    <td></td>
                </tr>
                { data !== null && data.polozky !== undefined && data.polozky.map((row,index) => {
                                        if (kosik.polozky !== undefined && kosik.polozky.length > 0 && (kosik.polozky[index-1] === undefined) || (kosik.polozky[index-1] !== undefined && row.supplier !== kosik.polozky[index-1].supplier)) {
                                            dodavatelNazev = kosik.polozky[index].supplier;
                                        } else dodavatelNazev = '';
                    
                    return <>
                               {dodavatelNazev && <tr className="bg-gray-500 text-white m-1"><td className='' colSpan={objednaciDny !== undefined ? objednaciDny.length+4 : 1}>{dodavatelNazev}</td></tr> }
                 
                            {row.row_qnt > 0 &&
                                <tr>
                                  <div className={(isSrolling ? "block" : "hidden")+" fixed top-[56px] p-2 bg-white w-full z-50"}>
                                        <div className="flex content-center bg-gray-100">
                                            <div className="font-bold flex-none pl-1 pt-3">Týden</div>
                                            {objednaciDny !== undefined && objednaciDny.map((row,index) =>{
                                                return <div align='center' className="pl-4 w-[76px]">{row.Dscr.split('-')[0]}<br />{row.Dscr.split('-')[1]}</div>
                                            })
                                            }
                                            <div  align='center' className="font-bold flex-auto pt-3">Celkem</div>
                                            <div className="flex-auto min-w-[35px]"  ></div>
                                            <div className="flex-auto" >
                                            </div>
                                        </div>
                                    </div>
                                    <td className="border-r border-black text-right text-gray-500">{row.product_no}</td>
                                    <td className="font-bold p-1" colSpan={objednaciDny !== undefined ? objednaciDny.length : 1}>{row.product_name}</td>        
                                    <td className="border-l border-black  w-[130px]">
                                            <div className="float-left text-gray-400 pl-[1px] text-[0.9em]">
                                                {formatNum(row.pcs)} {row.unit}
                                                    <br />
                                                    <div className=" text-nowrap whitespace-nowrap">{pointsText(row.points)}</div>
                                            </div>
                                            <div className="text-xl my-2 text-nowrap whitespace-nowrap text-red-600 float-right">{formatCena(row.promo_price)}</div>
                                        </td>                
                                </tr>
                            }
                           <tr>
                              <td className="border-r border-black"></td>
                               {row.row_qnt > 0 && objednaciDny !== undefined && objednaciDny.map((obj,index) =>{
                                    return <td align='center' className={"min-w-[50px] border-l border-gray-300 "}>
                                             <div className={"m-1 "+(row['available_'+obj.ord] === 0  || obj.active === 0 ? 'bg-gray-300' : '')}>
                                               {Intl.NumberFormat('cs-CZ').format(formatNum(row['week'+obj.ord]))}
                                             </div>
                                           </td>
                                })
                              }
                              { row.row_qnt > 0 && 
                                <td align='center' className={"font-bold border-l border-black"} >{Intl.NumberFormat('cs-CZ').format(formatNum(row.row_qnt))} {row.unit}</td>                             
                              }
                           </tr>               
                           </>
                    })
                }         
                <tr className="bg-gray-100 border-t border-black">
                    <td className="font-bold border-r border-black">Týdně</td>
                    { objednaciDny !== undefined && objednaciDny.map((obj,index) =>{
                        return <td align='center' className="border-l border-gray-300">{formatCena(data.sumy['week'+obj.ord])}</td>
                    })
                    }
                    <td className="border-l border-black"></td> 
             
                </tr>
                <tr className="bg-green-100 border-b border-black">
                    <td className="font-bold text-green-600 border-r border-black">Ušetříte</td>
                    {objednaciDny !== undefined && objednaciDny.map((obj,index) =>{
                        return <td align='center' className="border-l border-gray-300 text-green-600 font-bold">{formatCena(data.usetrite['week'+obj.ord])}</td>
                    })
                    }
                    <td align='center'  className="text-green-600 font-bold border-l border-black">Ušetříte<br />{formatCena(data.totalUsetrite)}</td> 
          
                       
                </tr>                            
                          
            </table>
        }
            
            {odmeny && odmeny.length > 0 && <div className="text-2xl font-bold m-3">Vaše odměny</div>
            }
            <table className="m-4">
                <tr>
                {odmeny && odmeny.map(pol => {
                    //console.log('ODNMEY',pol);
                        return (
                                    <td align='center' className="w-[150px] relative">
                                        <div className="text-center max-h-[6em]">
                                        <img className='h-[6em] mx-auto' src={"img/odmeny/r_"+pol.reward_id+'.jpg'} alt={null} />
                                        </div>
                                        <div className="text-center font-bold">{pol.name}</div>
                                        <div className="text-center font-bold">{pol.pcs} ks</div>
                                    </td>
                                )
                })
                }
                </tr>
            </table>

            
            {promo && promo.length > 0 && <div className="text-2xl font-bold m-3 pt-6">Vaše promo dárky</div>
            }

            <table className="m-4">
                <tr>
                {promo && promo.filter(item => item.Func === 0).map(pol => {
                    //console.log('promo',pol,"img/promo/p_"+pol.product_no+'.jpg');
                        return (
                                  
                                    <td align='center' className="w-[150px] relative">
                                        <div className="text-center max-h-[6em]">
                                        <img className='h-[6em] mx-auto' src={"img/promo/p_"+pol.product_no+'.jpg'} alt={null} />
                                        </div>
                                        <div className="text-center font-bold">{pol.product_name}</div>
                                        <div className="text-center font-bold">{pol.product_no}</div>
                                        <div className="text-center font-bold">{pol.pcs} ks</div>
                                    </td>
                                )
                })
                }
                </tr>
            </table>
            
            {(promo && promo.filter(item => item.Func === 1).length > 0) && <div className="text-xl font-bold m-3 pt-6 max-md:text-center">Bonusy jsou určeny pouze pro zákazníky přítomné na prezentacích</div>}
            
            <table className="m-4">
                <tr>
                {promo && promo.filter(item => item.Func === 1).map(pol => {
                    //console.log('promo',pol,"img/promo/p_"+pol.product_no+'.jpg');
                        return (
                                  
                                    <td align='center' className="w-[150px] relative">
                                        <div className="text-center max-h-[6em]">
                                        <img className='h-[6em] mx-auto' src={"img/promo/p_"+pol.product_no+'.jpg'} alt={null} />
                                        </div>
                                        <div className="text-center font-bold">{pol.product_name}</div>
                                        <div className="text-center font-bold">{pol.product_no}</div>
                                        <div className="text-center font-bold">{pol.pcs} ks</div>
                                    </td>
                                )
                })
                }
                </tr>
            </table>

            <div className="clear-both mt-3"></div>

            <div className="text-xl my-3 mx-2">{losyCelkem}</div>
            <button type="button" 
                  className=" text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-red-800/80 font-medium rounded text-sm px-4 py-3.5 text-center m-2"
                  onClick={(e) => {setZobrazeni('OBJEDNAVKOVELISTY');}}
            >ZPĚT K OBJEDNÁVCE</button>

            {data !== null &&
             <>
                <button type="button"
                        className="text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-red-800/80 font-medium rounded text-sm px-4 py-3.5 text-center me-1 mb-2"
                        onClick={(e) => {setZobrazeni('ODMENYVYBER');}}
                  >ZPĚT K ODMĚNÁM</button>
                     
                <button type="button"
                    className="mt-2 float-right text-white bg-blue-700 hover:bg-blue-500 font-medium rounded text-sm px-4 py-3.5 text-center me-1 mb-2"
                    onClick={(e) => potvrditObjednavku()}
                >DOKONČIT OBJEDNÁVKU</button>
             </>   

            }

        </div>        
    )
}

export default Rekapitulace;