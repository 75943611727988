import Zahlavi from "./Zahlavi";
import { useEffect, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthData } from "../../../auth/AuthWrapper";

import avatar from './../../../img/avatar.png';
import { getUrlApi, postData } from "../../../functions";
import Zavreno from "./Zavreno";

 const Prihlaseni = () => {   
     const navigate = useNavigate();
     const { login } = AuthData();
     const [ formData, setFormData ] = useReducer((formData, newItem) => { return ( {...formData, ...newItem} )}, {userNo: "", password: ""})
     const [ errorMessage, setErrorMessage ] = useState(null);
     const [zavreno,setZavreno] = useState(null);
     
     //nacteni vstupnich GET parametru url
     const queryParameters = new URLSearchParams(window.location.search)
     const promoCode = queryParameters.get("promoCode");
     //console.log('PROMO',queryParameters.get("promoCode"));

      //zjisteni nastaveni + priprava promoCodu do SESSION a po uspesnem prihlaseni se automaticky prida k verzi
      useEffect(() => {
        postData(getUrlApi(),{akce: 'config',promoCode : promoCode}).then(result => {
           
            if (result && result['status'] === 'OK') {
                if (new Date(result.config['nd_start']+' 00:00:00') <= new Date() && new Date(result.config['nd_konec']+' 23:59:00') >= new Date()) {
                    setZavreno(false);
                    
                } else setZavreno(true);    
            }
        });
      },[]);


      const Prihlasit = async () => {
  
          try {
               
               await login(formData.userNo, formData.password)
               navigate("/nejnakup")

          } catch (error) {
                //console.log('CATCHerrorPrihlasit',error);
               setErrorMessage(error)
               
          }
          
     }    

          function handleSubmit(event) {
              if (formData.userNo !== '' && formData.password.length > 3) Prihlasit();
              event.preventDefault();
            }

     return (
         <div className='relative h-screen bg-[#e6e6e6df]'>
              <Zahlavi />
           
            {zavreno !== null && zavreno === false &&
             <div className=" absolute w-full flex flex-col items-center mx-auto p-[100px] max-sm:pt-3 h-[calc(100vh-64px)]  dark:bg-gray-900">
                 
                 <div className="w-[350px] p-6 space-y-2 sm:p-8 bg-white rounded-lg shadow dark:bg-gray-800">
                     <div className='text-2xl font-bold m-5 text-center'>Přihlášení uživatele</div>
                     <img className='m-0 h-14 mx-auto' src={avatar} /> 
                         
                     
                     <form className="space-y-2" onSubmit={handleSubmit}>
 
                             <input onChange={(e) => setFormData({userNo: e.target.value}) } 
                                    type="number" 
                                    name="userNo" id="userNo"
                                    className="text-center bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" 
                                    placeholder="" required autoFocus/>
 
 
 
                             <input onChange={(e) => setFormData({password: e.target.value}) } 
                                    type="password" 
                                    name="password" id="password" placeholder="" 
                                    className="text-center p-2.5 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" required />
 
                         <button type='submit' className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full">
                           Přihlásit
                         </button>
                         <div className="hidden text-sm font-medium text-gray-500 dark:text-gray-400">
                             Not registered? <a className="text-primary-700 hover:underline dark:text-primary-500">Create account</a>
                         </div>
                     </form>
                     {errorMessage ? <div className="w-full text-center text-red-600 font-bold">{errorMessage}</div> : null}
                 </div>
                 
             </div>
             }
             {zavreno !== null && zavreno === true && <Zavreno /> }
            
        
 
 </div>
 );
 }

 export default Prihlaseni;