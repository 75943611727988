import { useEffect, useRef, useState } from "react";
import { getUrlApi, postData } from "../../../../functions";
import { AuthData } from "../../../../auth/AuthWrapper";

function Klavesnicka({dataKlavesnicka,setViewKlavesnicka,viewKlavesnicka,setZprava}) {
    const {setKosik} = AuthData();
    const [inData,setInData] = useState('');
    const refMnozstviInput = useRef();
    var addClass ='hidden';
    viewKlavesnicka !== false ? addClass = 'block' : addClass = 'hidden';
    
    useEffect(() =>{
          if (dataKlavesnicka !== null && Number(dataKlavesnicka['mnoz']) !== 0) setInData(dataKlavesnicka['mnoz']);
          //console.log('renderKLavesnicka',viewKlavesnicka,addClass,dataKlavesnicka);

          //focus na input
          document.getElementById('refMnozstviInput').focus();

    },[dataKlavesnicka]);
    
  const zmenaMnozstvi = () => {
     //console.log('doobjednat',inData,dataKlavesnicka);
     postData(getUrlApi(),{akce:'objednatJednotlive',productId: dataKlavesnicka['productId'],
                           mnoz: inData,
                           weekOrd: dataKlavesnicka['weekOrd']}).then(result => {
          if (result !== undefined && result.status === 'OK') {
               if (result.vysledek.length > 2) setZprava(result.vysledek);
               else {
                 setKosik({...result.kosik});
                 setViewKlavesnicka(false);
                 setInData('');
               }
          }    

     });
     
     
  }
    return (<>
             <div className={addClass+" backdrop-contrast-50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-30 justify-center items-center w-full  h-[calc(100%)] max-h-full"}>
                <div className="top-[30%] p-2 shadow-md w-[272px] h-[272px] relative border-2 border-black rounded bg-white m-auto ">
                <input type='text' 
                       id='refMnozstviInput'
                       className="shadow-md w-full mb-1"
                       value={inData}
                       onChange={(e) =>setInData(e.target.value)}
                             />
                    <div className="relative">
                         <div className="shadow-md float-left bg-orange-300 hover:bg-orange-400 h-12 w-12 p-3 mr-1  mb-1 text-center border-gray-500 border rounded  font-bold"
                              onClick={(e) =>{setInData(prevState => Number(String(inData)+'7'))}}
                              >7</div>
                         <div className="shadow-md float-left bg-orange-300 hover:bg-orange-400 h-12 w-12 p-3 mr-1  mb-1 text-center border-gray-500 border rounded  font-bold"
                              onClick={(e) =>{setInData(prevState => Number(String(inData)+'8'))}}
                              >8</div>
                         <div className="shadow-md float-left bg-orange-300 hover:bg-orange-400 h-12 w-12 p-3 mr-1  mb-1 text-center border-gray-500 border rounded  font-bold"
                              onClick={(e) =>{setInData(prevState => Number(String(inData)+'9'))}}
                              >9</div>                                                            
                        <div className="shadow-md float-left bg-orange-300 hover:bg-orange-400 h-12 w-24 p-3 m-0  mb-1 text-center border-gray-500 border rounded  font-bold"
                              onClick={(e) =>{setViewKlavesnicka(false);setInData('')}}
                              >Zpět</div>  

                          <div className="shadow-md float-left bg-orange-300 hover:bg-orange-400 h-12 w-12 p-3 mr-1 mb-1 text-center border-gray-500 border rounded  font-bold"
                              onClick={(e) =>{setInData(prevState => Number(String(inData)+'4'))}}
                              >4</div>
                         <div className="shadow-md float-left bg-orange-300 hover:bg-orange-400 h-12 w-12 p-3 mr-1 mb-1  text-center border-gray-500 border rounded  font-bold"
                              onClick={(e) =>{setInData(prevState => Number(String(inData)+'5'))}}
                              >5</div>
                         <div className="shadow-md float-left bg-orange-300 hover:bg-orange-400 h-12 w-12 p-3 mr-1 mb-1  text-center border-gray-500 border rounded  font-bold"
                              onClick={(e) =>{setInData(prevState => Number(String(inData)+'6'))}}
                              >6</div>                                                            
                        <div className="shadow-md float-left bg-orange-300 hover:bg-orange-400 h-12 w-24 p-3 m-0 mb-1 text-center border-gray-500 border rounded  font-bold"
                              onClick={(e) =>{setInData('')}}
                              >Vyčistit</div>    

                          <div className="shadow-md float-left bg-orange-300 hover:bg-orange-400 h-12 w-12 p-3 mr-1 mb-1  text-center border-gray-500 border rounded  font-bold"
                              onClick={(e) =>{setInData(prevState => Number(String(inData)+'1'))}}
                              >1</div>
                         <div className="shadow-md float-left bg-orange-300 hover:bg-orange-400 h-12 w-12 p-3 mr-1 mb-1  text-center border-gray-500 border rounded  font-bold"
                              onClick={(e) =>{setInData(prevState => Number(String(inData)+'2'))}}
                              >2</div>
                         <div className="shadow-md float-left bg-orange-300 hover:bg-orange-400 h-12 w-12 p-3 mr-1 mb-1  text-center border-gray-500 border rounded  font-bold"
                              onClick={(e) =>{setInData(prevState => Number(String(inData)+'3'))}}
                              >3</div>                                                            
                        <div className="shadow-md float-right  bg-orange-300 hover:bg-orange-400 h-[100px] w-24 p-8 ml-0 mr-0 mb-1 text-center border-gray-500 border rounded  font-bold"
                              onClick={(e) =>{zmenaMnozstvi();}}
                              >OK</div>                                   

                          <div className="shadow-md float-left bg-orange-300 hover:bg-orange-400 h-12 w-[100px] p-3 mr-1  mb-1 text-center border-gray-500 border rounded  font-bold"
                              onClick={(e) =>{setInData(prevState => Number(String(inData)+'0'))}}
                              >0</div>
                         <div className="shadow-md float-left bg-orange-300 hover:bg-orange-400 h-12 w-12 p-3 mr-1  mb-1 text-center border-gray-500 border rounded  font-bold"
                              onClick={(e) =>{setInData(prevState => String(inData)+'.')}}
                              >,</div>
                                                           
                 
                    </div>
                    
                </div>
              </div>
            </>
            )
}

export default Klavesnicka;