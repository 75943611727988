import { pointsText} from '../../../../functions'
import { AuthData } from "../../../../auth/AuthWrapper";
import Usetrite from "../Usetrite";

function InfoOdmeny({data}) {
    const {kosik} = AuthData();

    return (
        <>
          {data !== undefined && data !== null ?
                <div className='max-sm:text-center sm:float-left sm:w-[425px] max-sm:text-black max-sm:bg-yellow-300 text-white text-sm pl-1 pt-2 relative'>
                    <div  className='mx-auto '>Máte <span className="text-green-500 font-bold">
                        {data['reward'] !== undefined && data['reward'].length > 0 && pointsText(data['totalPoints'])}</span> > {data['reward']}
                    </div>
                    <div className="md:mx-auto w-full relative text-center">
                        {data['nextPoints'] > 0 && <div className="max-sm:text-center text-left">{'Jen ' + pointsText(data['nextPoints']-data['totalPoints'])+' > '+ data['nextReward']}</div> }
                        <div className="max-sm:block hidden"><Usetrite kosik={kosik} /></div>
                    </div>
                </div>
            : null }
        </>
    )
}

export default InfoOdmeny