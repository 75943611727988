import BombaCeny from "../pages/Nejnakup/BombaCeny"
import HlavniNabidka from "../pages/Nejnakup/HlavniNabidka"
import MojeObjednavka from "../pages/Nejnakup/MojeObjednavka"
import Nejnakup from "../pages/Nejnakup/Nejnakup"
import ObjednavkoveListy from "../pages/Nejnakup/ObjednavkoveListy"
import OdmenyVyber from "../pages/Nejnakup/OdmenyVyber"
import Prihlaseni from "../pages/Nejnakup/Prihlaseni"
import Profil from "../pages/Nejnakup/Profil"
import Rekapitulace from "../pages/Nejnakup/Rekapitulace"

export const nav = [
     { path:     "/",              name: "Home",                                               element: <Prihlaseni />,            isMenu: true,     isPrivate: false  },
     { path:     "/nejnakup",      name: "Alimpex - Nejnákup, užijte si výhodný nákup",        element: <Nejnakup />,             isMenu: false,    isPrivate: true  },
     { path:     "/objednavkovelisty",      name: "Objednávkové listy - Nejnákup, užijte si výhodný nákup",        element: <ObjednavkoveListy />,             isMenu: false,    isPrivate: true  },
     { path:     "/rekapitulace",      name: "Objednávkové listy - Nejnákup, užijte si výhodný nákup",        element: <Rekapitulace />,             isMenu: false,    isPrivate: true  },
     { path:     "/mojeobjednavka",      name: "Objednávkové listy - Nejnákup, užijte si výhodný nákup",        element: <MojeObjednavka />,             isMenu: false,    isPrivate: true  },
     { path:     "/nejnakup/hlavninabidka",      name: "Objednávkové listy - Nejnákup, užijte si výhodný nákup",        element: <HlavniNabidka />,             isMenu: false,    isPrivate: true  },
     { path:     "/nejnakup/profil",      name: "Objednávkové listy - Nejnákup, užijte si výhodný nákup",        element: <Profil />,             isMenu: false,    isPrivate: true  },
     { path:     "/nejnakup/odmenyvyber",      name: "Objednávkové listy - Nejnákup, užijte si výhodný nákup",        element: <OdmenyVyber />,             isMenu: false,    isPrivate: true  },
     { path:     "/bombaceny",      name: "Objednávkové listy - Nejnákup, užijte si výhodný nákup",        element: <BombaCeny />,             isMenu: false,    isPrivate: true  },
]