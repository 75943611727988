/* SPOLECNE FUNKCE K NEJNAKUP */

export const pointsText = (points) => {
  let text = 'bod';
  if (points > 1 && points < 5) text = 'body';
  if (points > 4 || points === 0) text = 'bodů';
  return points+' '+text;
}


export const formatNum = (num) => {
  if (num % 1 > 0) return (Math.round(num*100)/100).toFixed(2);
  else return Math.round(num).toFixed(0);
}

/* UNIVERZALNI FUNKCE */

export function formatCena(castka,mena = 'CZK') {
  return Intl.NumberFormat('cs-CZ',{ style: 'currency', currency: mena }).format(castka);
}

export function formatThousands (n, dp){
  var s = ''+(Math.floor(n)), d = n % 1, i = s.length, r = '';
  while ( (i -= 3) > 0 ) { r = ',' + s.substr(i, 3) + r; }
  return s.substr(0, i + 3) + r + 
    (d ? '.' + Math.round(d * Math.pow(10, dp || 2)) : '');
};

export function vycistitCestinu(text) {
  text = text.toLowerCase();
  text = text.replace(/á/g,'a');
  text = text.replace(/é/g,'e');
  text = text.replace(/í/g,'i');
  text = text.replace(/ó/g,'o');
  text = text.replace(/ů/g,'u');
  text = text.replace(/ú/g,'u');

  text = text.replace(/ž/g,'z');
  text = text.replace(/š/g,'s');
  text = text.replace(/č/g,'c');
  text = text.replace(/ř/g,'r');
  text = text.replace(/ď/g,'d');
  text = text.replace(/ť/g,'t');
  text = text.replace(/ň/g,'n');

  text = text.replace(/ /g,'_');
  return text;
}

export function getUrlApi() {
   // return  '/_apiReact/www/react_api.php';

    return  '/_apiReact/www/react_api.php';
} 



export function souboryKeStazeni(data) {
    
  if (data['keStazeni'].length == 0) document.getElementById('zprava').innerHTML = 'žádné soubory ke stažení';
  else {
      data.keStazeni.forEach(pol =>{
          var a = document.createElement('a');
          a.href = pol.cesta + pol.nazev;
          a.download = pol.nazev;
          a.click();
      });
      document.getElementById('zprava').innerHTML = '';
  }    
  return false;
}

export function formatDate({date,locales,options}) {
    return new Intl.DateTimeFormat(
      locales,
      options
    ).format(date);
  }

  export function jeCisloSude(n) {
    n = Number(n);
    return n === 0 || !!(n && !(n%2));
  }

  export async function postData(url = "", data = {}) {
    // Default options are marked with *
    try {
        const response = await fetch(url, {
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                mode: "cors", // no-cors, *cors, same-origin
                cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                credentials: "same-origin", // include, *same-origin, omit
                headers: {
                    "Content-Type": "application/json",
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                redirect: "follow", // manual, *follow, error
                referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: JSON.stringify(data), // body data type must match "Content-Type" header
                });
        const result = await response.json();
        //console.log("Success:", result);
        return result; // parses JSON response into native JavaScript objects
      }
     catch (error) {
        console.error("Error:", error);
      }
    
  }

  export async function upload(url,formData) {
    try {
      const response = await fetch(url, {
        method: "POST",
        body: formData,
      });
      const result = await response.json();
      console.log("SuccessUpload:", result);
    } catch (error) {
      console.error("ErrorUpload:", error);
    }
  }

  export function arrayRotate(arr, reverse) {
    if (reverse) arr.unshift(arr.pop());
    else arr.push(arr.shift());
    return arr;
  }

  export function imageExists(url) {
    return new Promise(resolve => {
      var img = new Image()
      img.addEventListener('load', () => resolve(true))
      img.addEventListener('error', () => resolve(false))
      console.log('ImageExist?',url);
      img.src = url
    })
  }

  export function imageExistsRequest(image_url){

      var result = null;
      fetch(image_url, { method: 'HEAD' })
      .then(res => {
        console.log('Image',res);
          if (res.ok) {
              return 1;
          } else {
              //console.log('Image does not exist.');
              return 0;
          }
      }).catch(err => console.log('Error pri kontrole img:', err));

}

  
export function checkBrowser(){
    let browser = "";
    let c = navigator.userAgent.search("Chrome");
    let f = navigator.userAgent.search("Firefox");
    let m8 = navigator.userAgent.search("MSIE 8.0");
    let m9 = navigator.userAgent.search("MSIE 9.0");
    if (c > -1) {
        browser = "Chrome";
    } else if (f > -1) {
        browser = "Firefox";
    } else if (m9 > -1) {
        browser ="MSIE 9.0";
    } else if (m8 > -1) {
        browser ="MSIE 8.0";
    }
    return browser;
}

export function validateEmail(mail) 
{
 if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
  {
    return true;
  }
    return false;
}

/* Fce nastavuje READONLY dle class 
Parametry:
    css_trida: string
*/
export function kecteniPodleTridy(css_trida) {
  const elementsArray = document.getElementsByClassName(css_trida);
  //console.log(elementsArray);
  [...elementsArray].forEach((element, index, array) => {
  element.readOnly = true;
  element.removeAttribute('onClick');
  element.disabled = true;
  });
}

/* Fce nastavuje a ruší zobrazení prvků dle class 
Parametry:
  css_trida: string
*/
export function zobrazitPodleTridy(css_trida) {
  const elementsArray = document.getElementsByClassName(css_trida);
  //console.log(elementsArray);
  [...elementsArray].forEach((element, index, array) => {
  element.style.display = 'block';
  });
}

/* Fce nastavuje a ruší skrytí prvků dle class 
Parametry:
  css_trida: string
*/
export function skrytPodleTridy(css_trida) {
  const elementsArray = document.getElementsByClassName(css_trida);
  //console.log(elementsArray);
  [...elementsArray].forEach((element, index, array) => {
  element.style.display = 'none';
  });
}

/* Fce vrací hodnotu cookie
Parametry:
  cname: string - název cookie
*/
export function getCookie(cname) {
let name = cname + "=";
let decodedCookie = decodeURIComponent(document.cookie);
let ca = decodedCookie.split(';');
for(let i = 0; i <ca.length; i++) {
  let c = ca[i];
  while (c.charAt(0) == ' ') {
  c = c.substring(1);
  }
  if (c.indexOf(name) == 0) {
  return c.substring(name.length, c.length);
  }
}
return "";
}

export function scrollToID(idObj,posun) {
  var fromTop = document.getElementById(idObj).getBoundingClientRect();
  //console.log('fromTop,', idObj,fromTop['y'] + 'px',fromTop);

  document.body.scrollTop = fromTop['y']+window.pageYOffset-posun; // For Safari
  document.documentElement.scrollTop = fromTop['y']+window.pageYOffset-posun; // For Chrome, Firefox, IE and Opera
}

export const isIOS = (function () {
  var iosQuirkPresent = function () {
      var audio = new Audio();

      audio.volume = 0.5;
      return audio.volume === 1;   // volume cannot be changed from "1" on iOS 12 and below
  };

  var isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  var isAppleDevice = navigator.userAgent.includes('Macintosh');
  var isTouchScreen = navigator.maxTouchPoints >= 1;   // true for iOS 13 (and hopefully beyond)

  return isIOS || (isAppleDevice && (isTouchScreen || iosQuirkPresent()));
})();

export function sha1 (str) {
  //  discuss at: https://locutus.io/php/sha1/
  // original by: Webtoolkit.info (https://www.webtoolkit.info/)
  // improved by: Michael White (https://getsprink.com)
  // improved by: Kevin van Zonneveld (https://kvz.io)
  //    input by: Brett Zamir (https://brett-zamir.me)
  //      note 1: Keep in mind that in accordance with PHP, the whole string is buffered and then
  //      note 1: hashed. If available, we'd recommend using Node's native crypto modules directly
  //      note 1: in a steaming fashion for faster and more efficient hashing
  //   example 1: sha1('Kevin van Zonneveld')
  //   returns 1: '54916d2e62f65b3afa6e192e6a601cdbe5cb5897'
let hash
try {
const crypto = require('crypto')
const sha1sum = crypto.createHash('sha1')
sha1sum.update(str)
hash = sha1sum.digest('hex')
} catch (e) {
hash = undefined
}
if (hash !== undefined) {
return hash
}
const _rotLeft = function (n, s) {
const t4 = (n << s) | (n >>> (32 - s))
return t4
}
const _cvtHex = function (val) {
let str = ''
let i
let v
for (i = 7; i >= 0; i--) {
v = (val >>> (i * 4)) & 0x0f
str += v.toString(16)
}
return str
}
let blockstart
let i, j
const W = new Array(80)
let H0 = 0x67452301
let H1 = 0xEFCDAB89
let H2 = 0x98BADCFE
let H3 = 0x10325476
let H4 = 0xC3D2E1F0
let A, B, C, D, E
let temp
// utf8_encode
str = unescape(encodeURIComponent(str))
const strLen = str.length
const wordArray = []
for (i = 0; i < strLen - 3; i += 4) {
j = str.charCodeAt(i) << 24 |
str.charCodeAt(i + 1) << 16 |
str.charCodeAt(i + 2) << 8 |
str.charCodeAt(i + 3)
wordArray.push(j)
}
switch (strLen % 4) {
case 0:
i = 0x080000000
break
case 1:
i = str.charCodeAt(strLen - 1) << 24 | 0x0800000
break
case 2:
i = str.charCodeAt(strLen - 2) << 24 | str.charCodeAt(strLen - 1) << 16 | 0x08000
break
case 3:
i = str.charCodeAt(strLen - 3) << 24 |
str.charCodeAt(strLen - 2) << 16 |
str.charCodeAt(strLen - 1) <<
8 | 0x80
break
}
wordArray.push(i)
while ((wordArray.length % 16) !== 14) {
wordArray.push(0)
}
wordArray.push(strLen >>> 29)
wordArray.push((strLen << 3) & 0x0ffffffff)
for (blockstart = 0; blockstart < wordArray.length; blockstart += 16) {
for (i = 0; i < 16; i++) {
W[i] = wordArray[blockstart + i]
}
for (i = 16; i <= 79; i++) {
W[i] = _rotLeft(W[i - 3] ^ W[i - 8] ^ W[i - 14] ^ W[i - 16], 1)
}
A = H0
B = H1
C = H2
D = H3
E = H4
for (i = 0; i <= 19; i++) {
temp = (_rotLeft(A, 5) + ((B & C) | (~B & D)) + E + W[i] + 0x5A827999) & 0x0ffffffff
E = D
D = C
C = _rotLeft(B, 30)
B = A
A = temp
}
for (i = 20; i <= 39; i++) {
temp = (_rotLeft(A, 5) + (B ^ C ^ D) + E + W[i] + 0x6ED9EBA1) & 0x0ffffffff
E = D
D = C
C = _rotLeft(B, 30)
B = A
A = temp
}
for (i = 40; i <= 59; i++) {
temp = (_rotLeft(A, 5) + ((B & C) | (B & D) | (C & D)) + E + W[i] + 0x8F1BBCDC) & 0x0ffffffff
E = D
D = C
C = _rotLeft(B, 30)
B = A
A = temp
}
for (i = 60; i <= 79; i++) {
temp = (_rotLeft(A, 5) + (B ^ C ^ D) + E + W[i] + 0xCA62C1D6) & 0x0ffffffff
E = D
D = C
C = _rotLeft(B, 30)
B = A
A = temp
}
H0 = (H0 + A) & 0x0ffffffff
H1 = (H1 + B) & 0x0ffffffff
H2 = (H2 + C) & 0x0ffffffff
H3 = (H3 + D) & 0x0ffffffff
H4 = (H4 + E) & 0x0ffffffff
}
temp = _cvtHex(H0) + _cvtHex(H1) + _cvtHex(H2) + _cvtHex(H3) + _cvtHex(H4)
return temp.toLowerCase()
}

