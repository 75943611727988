import { useEffect,  useRef, useState } from "react";
import { postData, getUrlApi } from "../../../functions";


function OdmenyVyber({setZobrazeni,setZprava}) {
    const [usedPoints,setUsedPoints] = useState(0);
    const [totalPoints,setTotalPoints] = useState(0);
    const [isSrolling,setIsScroolling] = useState(false);
    const [pokracovat,setPokracovat] = useState(false);
    const [userRewards,setUserRewards] = useState([]);

    const boxRef = useRef();

useEffect(() => {
  window.addEventListener("scroll", getPositionTop);
  //console.log('registruji eventu');

  postData(getUrlApi(),{akce: 'kontrolaObjednavky'}).then(result => {
        if (result !== undefined && result.status === 'OK') {
           
            
            if (result.stav !== 1) {
                setZprava('Odměny neodpovídají bodům');
                setPokracovat(false);    
            } else setPokracovat(true); 
            setUserRewards(result.rewards);  

            //nekdy muze vyjit zaporny stav -> pak bude 0
            if (result.usedPoints < 0) result.usedPoints = 0;
            setUsedPoints(result.usedPoints); 
        }
        setTotalPoints(result.totalPoints);

  });
  window.scrollTo({ top: 0, behavior: 'smooth' });

  return () => { window.removeEventListener("scroll", getPositionTop);

                }

}, []);


    let addClass= '';
    let addOpacity= '';

    const getPositionTop = () => {
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop
        //setY(y);
        //console.log('contionousPosition',document.documentElement.scrollHeight,document.documentElement.clientWidth,winScroll);
        var hranice = 60;
        if (document.documentElement.clientWidth<640) hranice=120;
        if (winScroll > hranice) setIsScroolling(true); else setIsScroolling(false);
      };
 

    const handleOdmenaClick = (item,akce,mnoz) =>{
        if (akce == '+') item.pcs = Number(item.pcs)+mnoz;
        else item.pcs = Number(item.pcs)-mnoz; 
        //console.log('handleOdmenaClick',item,akce,mnoz);

        postData(getUrlApi(),{akce: 'editOdmeny',odmenaId: item.ID,mnoz: item.pcs}).then(result =>{
            //console.log('handleOdmenaClickress',result);
            if (result.status == 'OK') {

            
            setUserRewards(result.rewards);  
            //nekdy muze vyjit zaporny stav -> pak bude 0
            if (result.usedPoints < 0) result.usedPoints = 0;
                setUsedPoints(result.usedPoints);                
            }

        });
 
    }

    return (
        <div className='w-full h-dvh relative'>

             <div  className={(isSrolling ? "block" : "hidden")+" fixed top-[56px] p-2 bg-white w-full z-50"}>
                <button type="button" 
                        className="float-left text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 shadow-lg shadow-green-500/50 dark:shadow-lg dark:shadow-green-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                        onClick={(e) => {setZobrazeni('MOJEOBJEDNAVKA');}} >
                    Zpět
                    </button> 
                <div className="font-bold text-xl px-4 pt-1 ">Odměny</div>
                <div className='float-left '>              
                    <div className={(totalPoints-usedPoints < 0 ? 'text-red-600' : '') +" pt-2 float-left font-bold"}>Zbývá: {(totalPoints-usedPoints) >= 0 ? totalPoints-usedPoints : '0'} bodů</div>
                </div> 

                    {pokracovat === true &&
                        <button type="button" 
                        className="float-right text-white bg-gradient-to-r from-blue-400 via-blue-500 to-blue-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                        onClick={(e) =>setZobrazeni('REKAPITULACE')}
                        >Potvrdit výběr odměn </button>
                    }    
                </div> 
                
             
           
        <div ref={boxRef}>
                <button type="button" 
                    className="m-2 float-left text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 shadow-lg shadow-green-500/50 dark:shadow-lg dark:shadow-green-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                    onClick={(e) => {setZobrazeni('MOJEOBJEDNAVKA');}}
                >Zpět</button>
                <div className="text-xl font-bold text-left p-4">Odměny</div>
                <div className={(totalPoints-usedPoints < 0 ? 'text-red-600' : '') +" m-3 w-[200px] float-left font-bold"}>Zbývá: {(totalPoints-usedPoints) >= 0 ? totalPoints-usedPoints : '0'} bodů</div>
                {pokracovat === true &&
                        <button type="button" 
                        className="float-right text-white bg-gradient-to-r from-blue-400 via-blue-500 to-blue-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                        onClick={(e) =>setZobrazeni('REKAPITULACE')}
                        >Potvrdit výběr odměn </button>
                }
            </div>
            <div className="clear-both"></div>
            <hr />
            <div className="p-2 grid grid-flow-row-dense max-md:grid-cols-2 grid-cols-3 xl:grid-cols-5 max-xl:gap-1 xl:gap-4 justify-items-center relative">
                   
            { userRewards.map((item,index) =>{
                       item.available == 1 ? addOpacity = '' : addOpacity = 'opacity-25';
                       addClass = ' border border-gray-200';
                       if (item.pcs > 0) addClass = ' border-[4px] border-green-600';
                
                       return( <>
                            <div key={item.ID} className={addClass+" p-4 relative max-md:w-[46vw] w-[250px] bg-white  rounded-xl shadow dark:bg-gray-800 dark:border-gray-700 "}>
                                {item.pcs > 0 && 
                                   <>
                                    <div className="z-30 absolute right-0 h-10 w-10 p-2 m-0 font-bold bg-green-600 text-white text-center">
                                      {item.pcs}x
                                    </div> 
                                    </>    
                                }
                            <div className={addOpacity}>
                                <div className="w-full relative h-[100px]">
                                    <img className='m-auto h-full' src={"img/odmeny/r_"+item.ID+'.jpg'} alt={null} />
                                </div>
                                <div className="pt-5">
                                    <p className="mb-3 text-center font-bold text-gray-700 dark:text-gray-400">{item.name2}</p>
                                    <div className="text-center font-bold">{item.points} bodů</div>
                                </div>
                              </div>
                                <div className={((totalPoints-usedPoints) >= item.points.split('-')[0] || item.pcs > 0 ? '' : 'opacity-25')+" p-5"}>
                                
                                <div className="flex w-[100px] mx-auto">      
                                    <button className="flex-shrink-0 z-10 inline-flex items-center p-2.5 px-[14px] text-sm font-medium text-center bg-blue-700 border border-e-0 dark:border-gray-700 dark:text-white rounded-s-lg border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 text-white" 
                                            type="button"
                                            onClick={(e) => {handleOdmenaClick(item,'-',1)}}>-</button>

                                    <div className="relative w-full">
                                        <input type="number" 
                                                value={item.pcs}
                                            className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-e-lg rounded-s-gray-100 rounded-s-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500" 
                                                />
                                        <button type="submit" 
                                                className="absolute top-0 end-0 p-2.5 h-full text-sm font-medium text-white bg-blue-700 rounded-e-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                                onClick={(e) => {handleOdmenaClick(item,'+',1)}} >+</button>
                                    </div>
                                </div>

                                </div>
                            </div>
                            </>
                        )
                    })
             
            }
            </div>

        </div>
    )
}

export default OdmenyVyber;