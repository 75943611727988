import { useEffect } from 'react';
import { useState } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import Sortiment from "./Sortiment";
import { AuthData } from '../../../auth/AuthWrapper';
import { getUrlApi, postData } from '../../../functions';

function Filtry({sortiment,setSortiment,filtr,setFiltr,setZobrazeni}) {
    const {dodavatel,setDodavatel,setPolozky,bombaCeny,setCategoryID} = AuthData();
    const [viewSortiment,setViewSortiment] = useState(false);
    let localFiltr = '';
    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
      } = useSpeechRecognition();

      var recognition = SpeechRecognition;

      useEffect(()=>{

        if (transcript.length > 0) {
          //console.log('slzsiiim',transcript);
          hledat(transcript);
        }  
      },[transcript]);

/*
      if (!browserSupportsSpeechRecognition) {
        return <span>Browser doesn't support speech recognition.</span>;
      }
*/

      const startSpeechListening = (e) => {
        
        recognition.continuous = false;
    
        //SpeechRecognition.
        recognition.startListening();
      }

      const hledat = (localFiltr) => {
        //console.log('HLEDATESUMBITed',localFiltr);
        let data = { akce: 'getProducts',dodavatelId:null,filtr: filtr,categories: sortiment};
        if (localFiltr !== null) {
          data['filtr'] = localFiltr;
          setFiltr(localFiltr);
        }  
          
          postData(getUrlApi(), data).then((result) => {
              if (result !== undefined && result.status === 'OK') {
                if (data['filtr'] !== '' ) setDodavatel(null); 
                else setDodavatel(result.dodavatel);
                setPolozky(result.polozky);   
                            
                //console.log('polozkyData',result);
              }
         }); 

    }

      const stiskKlavesy = (e) => {
        if (e.keyCode === 13)  hledat(null);
        return false;
      }

      const bombaCenyNabidka = () => {
        if (bombaCeny !== undefined) 
          return bombaCeny.seskupene.map(item => {
                  return <div className='border h-8 w-8 float-left mt-2'
                              onClick={() =>{setCategoryID(item.category);setZobrazeni('BOMBACENY')}} >
                            <img src={"img/sortiment/category_"+item.category+".png"} 
                                 alt={item.name+" ("+item.mnoz+")"} />
                         </div>
                 });
        }
      
      
    return (
        <div className='pl-1'>

                        <Sortiment sortiment={sortiment} 
                           setSortiment={setSortiment}
                           viewSortiment={viewSortiment} 
                           setViewSortiment={setViewSortiment}/>

            <div className='realitve pb-0 w-[170px] h-[14px]'>
              <div className='text-left font-bold mb-[-10px]'>Bomba ceny</div> 
              <div className=''>{bombaCenyNabidka() }</div>
            </div>
            <div className='pb-1'>
              <button type="button" 
                              className='float-right font-bold text-sm px-3 max-xl:me-1 py-2.5 my-1 text-blue-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-1 focus:ring-gray-200 rounded-sm dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700'
                              onClick={(e) => {setViewSortiment(true)}}
                      >SORTIMENT</button>  
            </div>
                    <div className="float-right max-sm:w-full p-0 mb-1 w-[350px] flex items-center relative">   
                        <label for="voice-search" className="sr-only">Search</label>
                        <div className="relative w-full">
                            <input type="text" 
                                   id="voice-search" 
                                   value={filtr}
                                   onChange={(e) =>{setFiltr(e.target.value)}}
                                   onKeyDown={stiskKlavesy}
                                   className=" block w-full ps-7 p-2.5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                                   placeholder="produkt, číslo"  />

                            <button type="button" 
                                    className="absolute inset-y-0 start-0 flex items-center text-gray-400 p-2  bg-transparent hover:text-blue-500 text-sm justify-center" 
                                    onClick={(e) =>{hledat('');}}
                                    title='vymazat vyhledávání'>
                                <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                            </button>

                            {browserSupportsSpeechRecognition &&                            
                              <button type="button" 
                                      className="absolute inset-y-0 end-0 flex items-center pe-2"
                                      onClick={startSpeechListening}>
                                  <svg className={listening ? 'text-blue-500' : 'text-gray-500'+" w-4 h-4  dark:text-gray-400 hover:text-gray-900 dark:hover:text-white"}
                                        ariaHidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 20">
                                      <path stroke="currentColor" 
                                            strokeLinecap="round" 
                                            strokeLinejoin="round" 
                                            strokeWidth="2" 
                                            d="M15 7v3a5.006 5.006 0 0 1-5 5H6a5.006 5.006 0 0 1-5-5V7m7 9v3m-3 0h6M7 1h2a3 3 0 0 1 3 3v5a3 3 0 0 1-3 3H7a3 3 0 0 1-3-3V4a3 3 0 0 1 3-3Z"/>
                                  </svg>
                              </button>
                            }
                        </div>

                        <button title="Hledat"
                                onClick={() =>hledat(null)}
                                className="py-2.5 px-2.5 ms-2 me-1 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                            <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                            </svg>
                        </button>
                   </div>

        </div>
    )
}

export default Filtry;