
function Mapa() {

    return (
        <div className='w-full relative'>
          <img src='img/mapa/mapa.jpg' alt={null}
               className="w-full" />
        </div>
    )
}

export default Mapa;