import { useEffect, useState } from "react";
import { getUrlApi, postData } from "../../../functions";
import { AuthData } from "../../../auth/AuthWrapper";


function BombaCenyVyber ({onClickAkce}) {
    const {bombaCeny} = AuthData();
    const [bombaCenyGrouped,setBombaCenyGrouped] = useState([]);

    useEffect(() => {
        setBombaCenyGrouped(bombaCeny.seskupene);
    },[]);

    return (<div className="relative p-[2vw] max-w-[600px] mx-auto">
              {bombaCenyGrouped.map(category =>{
                       
                    return <div className="float-left border w-[50%] h-[80px] relative "
                                onClick={() =>{onClickAkce(category.category)}}>
                           
                                <div className="w-full relative">
                                    
                                    <img src={'./img/sortiment/category_'+category.category+'.png'} 
                                        alt={category.name} 
                                        className="float-left max-h-[60px] m-[0.8vw]"/>
                                    <div className="float-left pt-3 w-[55%]">
                                         <span className="block text-center max-sm:text-[0.8em] leading-[1.1em]">{category.name.toUpperCase()}</span>
                                        <div className="block text-center  "> ( {category.mnoz} )</div>
                                    </div>    
                                </div>
                            </div>

              })}
            <div className="clear-both"></div>
            </div>

           )
}

export default BombaCenyVyber;