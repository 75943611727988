
function Zapati() {

    return (
        <div className="relative m-1 leading-[0.9em]">
            <div className='w-full text-center'>

                <div>ALIMPEX FOOD a.s.</div>
                <div className="text-sm">IČ: 47115807</div>
                <div className="text-sm">DIČ: CZ47115807</div>
                <div>Českobrodská 1174</div>
                <div>198 00 Praha 9 - Kyje</div>
            </div>
        </div>          
    )
}

export default Zapati;