import { useEffect, useState } from "react";

function Message ({viewMessage,setViewMessage,text}) {
    let addClass = 'hidden';
    const [hlaska,setHlaska] = useState('');
            
    viewMessage !== false ? addClass = 'block' : addClass='hidden';
    //console.log('Message',viewMessage);

    useEffect(() => {
        setHlaska(text);
        //console.log('zmena msg')
      },[viewMessage]);

    return (
                <div id="alert-5" className={addClass+" flex items-center mx-2 mb-1 p-4 rounded-lg bg-red-300 dark:bg-gray-800"} role="alert">
                    <svg className="flex-shrink-0 w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                        <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v5a1 1 0 1 0 2 0V8Zm-1 7a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2H12Z" clip-rule="evenodd"/>
                    </svg>

                    <span className="sr-only">Upozornění</span>
                    <div className="ms-3 text-sm font-medium text-gray-800 dark:text-gray-300">
                        {hlaska}
                    </div>
                    <button type="button" 
                            onClick={(e) =>{setHlaska('');setViewMessage(false)}}
                            className="ms-auto -mx-1.5 -my-1.5 bg-gray-50 text-gray-500 rounded-lg focus:ring-2 focus:ring-gray-400 p-1.5 hover:bg-gray-200 inline-flex items-center justify-center h-8 w-8 dark:bg-gray-800 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white" data-dismiss-target="#alert-5" aria-label="Close">
                        <span className="sr-only">Dismiss</span>
                        <svg className="w-3 h-3" 
                            aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                    </button>
            </div>
)
}

export default Message;