

function Zavreno () {


    return (   <div className="mx-auto max-w-[1000px] relative h-dvh">
                
                
                <div className="text-center m-5">
                    <div className="text-3xl w-full ">
                            MOMENTÁLNĚ NEPROBÍHAJÍ ŽÁDNÉ<br /> 
                            SPECIÁLNÍ OBJEDNÁVKOVÉ AKCE
                    </div>
                 </div>
                <div className="my-5 mx-1">
                    Vážení zákazníci,<br /><br />
                    právě se nacházíte na webových stránkách nákupních dní společnosti ALIMPEX FOOD a.s. 
                    Momentálně bohužel žádné speciální akce neprobíhají. O další možnosti výhodných objednávek budete včas informováni.<br /><br />
                    Váš tým ALIMPEX FOOD a.s.
                </div>
                <img className='text-center px-1 max-w-[100%]' src={'img/vypnuto/pastikyPdCh.jpg'} alt={""} />  
                <img className='text-center px-1 max-w-[100%]'  src={'img/vypnuto/krajankaZakys.jpg'} alt={""} />  
                <img className='text-center px-1 max-w-[100%]'  src={'img/vypnuto/hochwald.jpg'} alt={""} />  

                <img className="mx-auto m-5" src={'img/alimpex/Logo_small.png'} alt={""} />  
               </div >
    )
}

export default Zavreno;