import { AuthData } from "../../../auth/AuthWrapper";

export const nastavitDrobecky = (klic,setDrobecky) => {
  let data = [];
   switch (klic) {
    case 'OBJEDNAVKOVELISTY' : data = [{text: 'Objednávkové listy',klic: 'OBJEDNAVKOVELISTY'}];
    break;
    case 'ODMENYVYBER' : data = [{text: 'Objednávkové listy',klic: 'OBJEDNAVKOVELISTY'},
                                 {text: 'Moje objednávka',klic: 'MOJEOBJEDNAVKA'},
                                 {text: 'Odměny za Vaší objednávku',klic: 'ODMENYVYBER'}]; 
    break;
    case 'PROFIL' : data = [{text: 'Profil',klic: 'PROFIL'}]; 
    break;
    case 'SCANNER' : data = [{text: 'Kde jsem - Čtečka',klic: 'SCANNER'}]; 
    break;   
    case 'MAPA' : data = [{text: 'Mapa',klic: 'MAPA'}]; 
    break;       
    case 'BOMBACENY' : data = [{text: 'Bomba ceny',klic: 'BOMBACENY'}]; 
    break;    
    case 'MOJEOBJEDNAVKA' : data = [{text: 'Objednávkové listy',klic: 'OBJEDNAVKOVELISTY'},
                                    {text: 'Moje objednávka',klic: 'MOJEOBJEDNAVKA'}];
    break;   
    case 'REKAPITULACE' : data = [{text: 'Objednávkové listy',klic: 'OBJEDNAVKOVELISTY'},
                                    {text: 'Moje objednávka',klic: 'MOJEOBJEDNAVKA'},
                                    {text: 'Odměny',klic: 'ODMENYVYBER'},
                                    {text: 'Rekapitulace',klic: 'REKAPITULACE'}];
    break;            
    default : data = [{text: 'Objednávkové listy',klic: 'OBJEDNAVKOVELISTY'},
                      {text: klic,klic: ''}];
  }
  //console.log('nastavuji drobecky',data);
  setDrobecky([...data]);
} 


function DrobeckyNav({drobecky,setZobrazeni}) {
    const {setDodavatel} = AuthData();
   
    return (
        <div className='w-full text-[1em]'>
            <div className='p-0 pt-1 mb-0  mt-0'>
                <div className="inline space-x-1 font-medium md:space-x-2">
                  <div onClick={(e)=>{setZobrazeni('HLAVNINABIDKA')}} className="inline float-left">
                    <div className='inline float-left text-gray-700 hover:text-blue-600 dark:text-gray-300 dark:hover:text-white cursor-pointer  underline'>
                      <svg className="float-left w-5 h-5 mr-2.5 ml-1 mt-[3px]" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
                      </svg>
                      Hlavní nabídka
                    </div>
                  </div>

                  { drobecky !== undefined ? drobecky.map((polozka,index) =>{
 
                    return <div className="float-left">
                            <div className="">
                                <svg className="float-left w-5 h-5 m-1 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                  <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                                {index !== (drobecky.count-1) ?
                                <div onClick={(e)=>{setZobrazeni(polozka.klic);}} 
                                     className='text-nowrap whitespace-nowrap  mx-3 text-gray-700 hover:text-blue-600 md:ml-2 dark:text-gray-300 dark:hover:text-white cursor-pointer'>
                                  {polozka.text}
                                </div>
                                :
                                <span onClick={(e)=>{setZobrazeni(polozka.klic);}} 
                                      className='ml-1 text-gray-400 md:ml-2 dark:text-gray-500' aria-current="page">
                                  {polozka.text}
                                </span>          
                                }
                                
                            </div>
                          </div>
                    })           
                    : null
                  }
                </div>
            </div>
            <div className="clear-both"></div>
        </div>
    )
}

export default DrobeckyNav;