import { useEffect, useState } from "react";
import Tlacitko from "./Tlacitko";
import { getUrlApi, postData } from "../../../../functions";
import { AuthData } from "../../../../auth/AuthWrapper";


function VlozitAkcniKod ({setZprava,setZobrazeni,scanResult}) {
    const {kosik,setKosik} = AuthData();
    const [kod,setKod] = useState('');
 

    useEffect(() => {
            if (scanResult !== null ) {
                setKod(scanResult);
                setZobrazeni('PROFIL');
                odeslatKod();
                //console.log('SCANRESULT',scanResult);
               };   
    },[scanResult]);


    const odeslatKod = () => {
        postData(getUrlApi(),{akce:'vlozitPromoKod',kod: kod}).then(result =>{
            if (result !== undefined && result.status === 'OK') {
                if (result['promoKod'] === 0) setZprava("Neplatný PROMO kód");
                else {
                    kosik['promoCode'] = kod;
                    setKosik({...kosik});
                }    
            }
        });
    }


    /*
                    <button className="float-left border border-black relative mt-1 mr-5 bg-gray-200  hover:bg-gray-300"
                            onClick={() =>setZobrazeni('SCANNER')} >

                        <div className="p-1 py-2 float-left font-bold">Načti</div>    
                        <div className="float-left ml-0 relative ">
                            <img src='img/qr.png' className='h-[34px] m-[3px]' alt='' />
                        </div>       
                    </button>        
    */
   
    return (<>
             <div id="reader" width="600px"></div>
             <div className="text-left font-bold pt-1">
                Váš PROMO kód
            </div >
            {kosik.promoCode === null ?
                <>
                  <div className="">
                    <input className={"mt-1 w-[80px] placeholder:text-gray-300 float-left"} 
                        type="text" 
                        value={kod} 
                        onChange={(e) =>setKod(e.target.value)}
                        placeholder="XXXXX" /> 
 
                    <Tlacitko popis='Potvrdit'
                            barva='blue' akceOnClick={(e)=>{e.preventDefault();odeslatKod()}} />
                   </div>  
                </>
            : <div className="text-yellow-500 font-bold">Kód vložen, děkujeme za Vaši účast</div>
            }
            </>
           )
}

export default VlozitAkcniKod;