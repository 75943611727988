import {Html5QrcodeScanner} from "html5-qrcode";
import { useEffect, useState } from "react";
import { AuthData } from "../../../auth/AuthWrapper";

function Scanner({scanResult,setScanResult,setZobrazeni}) {
  const {dodavatele,setDodavatel} = AuthData();
  const [viewHtml,setViewHtml] = useState(null);

    useEffect(()=>{
        function onScanSuccess(decodedText, decodedResult) {
            html5QrcodeScanner.clear();
            // handle the scanned code as you like, for example:
            console.log(`Code matched = ${decodedText}`, decodedResult);
            alert('DOD '+decodedText+' x '+dodavatele[0].supplier);
          }
          
          function onScanFailure(error) {
            // handle scan failure, usually better to ignore and keep scanning.
            // for example:
            console.warn(`Code scan error = ${error}`);
          }
          
          let html5QrcodeScanner = new Html5QrcodeScanner(
            "reader",
            { fps: 10, qrbox: {width: 350, height: 350} },
            /* verbose= */ false);
          html5QrcodeScanner.render(onScanSuccess, onScanFailure);
    

          //vola se při opuštění componenty
          return function cleanup() {
            if (html5QrcodeScanner.isScanning) {
              html5QrcodeScanner
                .stop()
                .then(() => {
                  console.log("Scanner stopped successfully");
                  html5QrcodeScanner.clear();
                })
                .catch((error) => {
                  console.error("Error stopping scanner:", error);
                });
            } else {
              html5QrcodeScanner.clear();
            }

            console.log('Scannerunmount');
            setZobrazeni('OBJEDNAVKOVELISTY');
          };

    },[]);

  
    return ( <>
              <div id="reader" width="600px"></div> 
             </>
    )
}

export default Scanner;