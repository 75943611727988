import { useEffect, useState } from "react";
import { formatNum, pointsText,imageExistsRequest, imageExists, postData, getUrlApi } from "../../../functions";
import DetailProduktu from "./DetailProduktu";

import { AuthData } from "../../../auth/AuthWrapper";
import Zapati from "./Zapati";

function ObjednatDodavatel({kosik,setAktivniPolozka,setDrobecky}) {
    const {polozky,dodavatel,nastaveni,infoLosy} = AuthData();
    const [detailProduktu, setDetailProduktu] = useState(null);
    const [objednaciDny,setObjednaciDny] = useState(null);   
    
    let obalObrazku;
 

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        postData(getUrlApi(),{ akce: 'getWeeks'}).then(result => {
            if (result !== undefined && result.status === 'OK') {
               setObjednaciDny(result['weeks']);
            }
        });

    },[dodavatel]);
    

    const PoleObjednat = (polozka) => {
       
        var objednano = kosik.polozky.filter(item => polozka.id === item.product_id);
        //console.log('POLOZKY',kosik,polozka,objednano);

         if (objednano.length > 0 && objednano[0].row_qnt > 0) {
            objednano = objednano[0].row_qnt;
            polozka.unit === 'ks' ? objednano = Math.round(objednano).toFixed(0) : objednano = (Math.round(objednano*100)/100).toFixed(1);

            return <td className='p-1 w-[80px] text-center font-bold bg-blue-600 hover:bg-blue-900 cursor-pointer text-white' 
                       onClick={(e) =>{setAktivniPolozka(polozka);e.stopPropagation();}}>
                          <div>OBJEDNAT <br /> <div  className="font-normal">{'('+objednano+')'}</div></div>
                   </td>      
        } else 
            return <td className='p-1 w-[80px] text-center font-bold bg-blue-800 hover:bg-blue-900 cursor-pointer text-white' 
                       onClick={(e) =>{setAktivniPolozka(polozka);e.stopPropagation();}}>OBJEDNAT
                    </td>
        

    };




    const getPromoTexty = (promo_id) => {
        
        let data = nastaveni['promo'].filter(item => item.id === promo_id);
            //console.log('promo',promo_id,data,nastaveni['promo']);
            return data;
        }

    const kdyzNarokPromo = (item,barva) => {
        
        if (kosik.promoCode ==  null && item.PromoFunc.indexOf('0') === -1) barva = '';
        return barva;
    }

    const zobrazitObrazek = (item,obalObrazku) => {
        //var imgUrl = 'https://obrazky.alimpexfood.cz/viewimg.php?size=220&cisloproduktu='+item.product_no;
        
           // console.log('existImg?',imgUrl,imageExistsRequest(imgUrl));

            var nazev = item.product_no+'.png';
           // if (item.product_name.indexOf('MIX') > -1) nazev = item.product_no+'  MIX.png';
        
        return ( 
            <>
              <img ref={obalObrazku} src={'https://obrazky.alimpexfood.cz/DATA_x220/'+nazev}  
                   className='mx-auto opacity-90 h-[60px] max-w-[60px] p-0 pl-1 hover:scale-[3] hover:absolute hover:z-10 hover:ml-[80%] hover:opacity-100' alt={''} />
   
          </>
        )
    }

    return (
        <>
            <div className='w-full'>
                {detailProduktu !== null ?
                                <DetailProduktu setDetailProduktu={setDetailProduktu} 
                                       polozka={detailProduktu} 
                                       dodavatel={dodavatel}
                                       objednat={setAktivniPolozka} 
                                       setDrobecky={setDrobecky}/>      
                                :         
                <>
                {dodavatel !== undefined && dodavatel !== null && 
                   <div className='p-2 w-full text-xl font-bold  bg-blue-900 text-white'>
                     {dodavatel.supplier}
                     <div className="sm:float-right pb-2">{infoLosy}</div>
                   </div>
                }
                
                  <table className='w-full bg-white  rounded-md p-1 select-text'>
                        <tbody>
                            {polozky.map((item,index) => {
                               
                                return <>
                                        {item.Supplier !== '' && (polozky[index-1] === undefined || polozky[index-1].Supplier !== item.Supplier) && 
                                                                <tr key={'suplier'+item.id} className="bg-blue-900 p-2">
                                                                    <td colSpan={5} className="text-xl text-white font-normal mb-2 ">{item.Supplier}</td>
                                                                </tr> 
                                        } 
                                        {item.dis_id !== null && (polozky[index-1].dis_id === null ) ? 
                                            <>
                                                <tr key={'SLEVY'+item.id} className="text-xl bg-red-500 w-full text-center">
                                                    <td colSpan={5} className="p-4">SUPER AKČNÍ CENOVÁ NABÍDKA ZA MINIMÁLNÍ ODBĚRY</td>
                                                </tr>                                           
                                            </> 
                                        : null } 


                                        <tr key={item.id}  className={'h-[80px] border-b p-0 border-gray-400 '+(item.promo_id > 0 ? kdyzNarokPromo(item,'bg-yellow-100') : item.dis_id  !== null ? 'bg-red-100' : null)}>
                                                
                                        <td className="w-[60px] relative">
                                        
                                        {zobrazitObrazek(item,obalObrazku)}
                                          
                                        </td>                  
                                        <td className='p-1 text-[1em] max-sm:text-[0.8em] md:text-[1.2em] max-md:break-all'>
                                                {item.product_name}
                                        </td>
                                        <td className='text-gray-400 pl-[1px] text-[0.9em]'>
                                            <span className="text-black">{item.product_no}</span>
                                            <br />
                                            {formatNum(item.pcs)} {item.unit}<br /><div className=" text-nowrap whitespace-nowrap">{pointsText(item.points)}</div> 
                                            
                                            
                                        </td>
                                        
                                        <td > 
                                            <div className='m-0 px-[1px] pt-3 text-red-700 text-[20px] max-sm:text-[18px]'>{item.promo_price}&nbsp;Kč</div>
                                            <div className='m-0 pb-2 px-[1px]  text-gray-500'>{item.price}&nbsp;Kč</div>
                                            
                                        </td>
                                        {PoleObjednat(item)}                       
                                    </tr>
                                
                                    {item.note !== '' ? 
                                    
                                        <tr key={'dis'+item.id} className="bg-red-300  w-full text-center text-black border-b-0 border-black ">
                                            <td colSpan={5} className="p-3">{item.note}</td></tr> : null } 

                                                                            
                                    {item.promo_id > 0 && (polozky[index+1] === undefined || polozky[index+1].promo_id !== item.promo_id) ? 
                                       getPromoTexty(item.promo_id).map(promo => {
                                        var napoveda = '';    
                                        if (promo.Func === 0 || kosik.promoCode !==  null && promo.Func === 1) {
                                            napoveda = 'Děkujeme za Vaši účast, Váš Alimpex';    
                                            return <tr key={'promo'+promo.Serial} 
                                                       title={napoveda} 
                                                       className={" bg-[#fffc47] w-full text-center text-black border-b border-gray-500 "} >
                                                        <td colSpan={5} className="p-3">{promo.Note}</td>
                                                    </tr>
                                            }  
                                        })
                                         : null } 
                                    </>
                                        

                                            
                                    
                                })
                            }
                            {polozky.length < 1 && 
                                    <div className="text-center font-bold m-10">
                                        Bohužel nebyly nalezeny žádné položky
                                    </div>
                            }
                        </tbody>
                
                    </table>
  
                </>
               }    
            </div>

        </>
    )
}

export default ObjednatDodavatel;