import React, { useEffect, useState } from "react"
import { postData,getUrlApi } from "../../../functions";
import { AuthData } from "../../../auth/AuthWrapper";

function Dodavatele({setViewDodavatele,setFiltr,sortiment,setInfoLosy}) {
    const {kosik,nastaveni,setPolozky,setNastaveni,dodavatel,setDodavatel} = AuthData();
   

    useEffect(() =>{
       if (dodavatel !== undefined && dodavatel !== null && dodavatel.id > 0 && dodavatel.supplier !== '' && dodavatel.supplier !== null ) {
            postData(getUrlApi(), { akce: 'getProducts',dodavatelId:dodavatel.id,filtr: '',categories: sortiment}).then((result) => {
              if (result !== undefined && result.status === 'OK') {
                
                setPolozky(result.polozky);               
                //console.log('polozkyData',result);

                setInfoLosy(result.losy);
              }
        }); 
        setFiltr('');
       }
    },[dodavatel]);

      const getDodavatelBody = (dodavatelId) => {
           var body = '';
           var soucet = 0;
           var pol = [];
           if (kosik !== undefined) pol = kosik.polozky.filter(item=>item.supplier_id === dodavatelId);
           if (pol.length > 0) {
              pol.forEach(element => {
                soucet += Number(element.point);
              })

              body = '('+String(Math.floor(soucet))+'b)';
           }
           return body;
      };

      const nacistPolozky = (dod) => {
            setDodavatel(dod);
        }


      //console.log('RENEDERdodavatele',dodavatel,nastaveni.dodavatele);
    
      return (
        <div className=' h-screen w-[225px] relative mb-5 overflow-y-scroll'>
          <div className='mb-1 w-full text-center text-xl'>
            <div className='pt-1 font-bold inline'>Dodavatelé</div>
              <svg onClick={(e) => setViewDodavatele('translateX(100%)')} 
                  className="lg:hidden m-2 float-right w-4 h-4 hover:text-white text-black inline-block" 
                  aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m13 7-6 6m0-6 6 6m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
              </svg>
            </div>
          <div className='pb-3 w-full rounded-sm  max-md:max-h-screen relative overflow-hidden max-md:overflow-y-scroll'>
          {nastaveni !== undefined && nastaveni.dodavatele !== undefined && nastaveni.dodavatele.length > 0 && (
            <table className="mb-[6em]">
              <tbody>
              {nastaveni.dodavatele.map((dod,index) => (
                <tr key={dod.id} onClick={()=>{setFiltr('');nacistPolozky(dod);setViewDodavatele('translateX(100%)')}} 
                    className={(dodavatel && dodavatel.id === dod.id && 'font-bold border-t border-b border-black')} style={{'background-color': dod.menu_color}}>

                  <td className='px-[6px] text-[14px] hover:underline ' >{dod.supplier}</td>
                  <td className={''} ><div>{getDodavatelBody(dod.id)}</div></td>
                </tr>
              ))}
            </tbody>
            </table>
          )}
          </div>
        </div>
      );
}

export default Dodavatele;