import Tlacitko from "./Tlacitko";

function Oznameni ({zprava,setZprava,akceOK}) {
    var zobrazit = 'hidden';
    if (zprava !== '') zobrazit = 'block';
    return (
        <>
        <div className={zobrazit+" backdrop-contrast-[25%] overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full  h-screen max-h-full"}>
          <div className="relative top-[30%] p-0 max-xl:w-full md:w-[430px] max-h-full ml-auto mr-auto mt-8 ">
            <div className="text-center w-full p-2 pb-10 relative bg-white rounded border-2 shadow-xl border-[#858484] dark:bg-gray-600">
                <button type="button" 
                            className="float-right text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" 
                            onClick={(e) =>setZprava('')}
                            title='Zavřít'>
                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                    </button>
                <div className="whitespace-pre-line clear-both text-[1.5em] p-1">{zprava}</div>
            </div>
           </div>
        </div>    
        </>
    )
}

export default Oznameni;