

function Tlacitko ({popis,barva,akceOnClick}) {

    return ( <button type="submit" 
                     className={"m-1 text-white bg-gradient-to-r from-"+barva+"-400 via-"+barva+"-500 to-"+barva+"-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-"+barva+"-300 dark:focus:ring-"+barva+"-800 shadow-lg shadow-"+barva+"-500/50 dark:shadow-lg dark:shadow-"+barva+"-800/80 font-medium rounded text-sm px-2.5 py-2.5 text-center mb-2"}
                     onClick={akceOnClick}
             >{popis}
             </button>
           )
}

export default Tlacitko;