import { useState } from "react";
import { getUrlApi, postData,isIOS } from "../../../functions";
import { AuthData } from "../../../auth/AuthWrapper";
import VlozitAkcniKod from "./components/VlozitAkcniKod";


function Profil({setZobrazeni,setZprava,scanResult}) {

    const {user,setUser,nastaveni} = AuthData();
    const [uzivatel,setUzivatel] = useState(user);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setUzivatel((prevProps) => ({
          ...prevProps,
          [name]: value
        }));
      };

      const handleSubmit = (event) => {
        //console.log(event);
        event.preventDefault();
        setZobrazeni('HLAVNINABIDKA');
        
        let url = getUrlApi();
        let data = uzivatel;
        data.akce = 'editProfil';
       // console.log(url,data);
        postData(url,data).then(result =>{
            //console.log(result);
        });

        setUser(uzivatel);
        
      };
    
    /* MAIN scripts */

    window.scrollTo({ top: 0, behavior: 'smooth' });  
    
    return (
        <div className="relative ml-auto mr-auto max-w-[500px] max-sm:w-full bg-gray-100 p-10 pb-20 m-2 rounded">

            <div className="float-right m-1 text-sm">{isIOS ? 'iOS' : ''}</div>

            <div className='text-2xl font-bold ml-2 mb-6' >Profil uživatele</div>
            <div className='text-base font-bold ml-2' >Kontaktní údaje uživatele</div>
            <form onSubmit={handleSubmit} >
            <div className='relative w-full m-2'>
                    <input type="email" 
                        id="floating_outlined" 
                        className="block px-2.5 pb-2.5 pt-4 w-full text-sm  rounded border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                        placeholder=" " 
                        name='email'
                        value={uzivatel.email} 
                        onChange={handleInputChange} 
                        required />
                    <label htmlFor="floating_outlined" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
                        Email
                    </label> 
                </div>

                <div className='relative w-full m-2'>
                    <input type="tel" id="floating_outlined" className="block px-2.5 pb-2.5 pt-4 w-full text-sm  rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                    placeholder=" "
                    value={uzivatel.phone} 
                    name='phone'
                    onChange={handleInputChange}
                    required />
                    <label htmlFor="floating_outlined" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
                        Telefon
                    </label> 
                </div>
                <br />


                <div className='text-base font-bold ml-2' ></div>
                
                <div className='relative  w-[230px] m-2'>
                    <input type="text" 
                        id="floating_outlined" 
                        className="block px-2.5 pb-2.5 pt-4 w-full text-sm  rounded border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                        placeholder="Ulice a číslo popisné" 
                        name='street'
                        value={uzivatel.street} onChange={handleInputChange} />
                    <label htmlFor="floating_outlined" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
                    Ulice a číslo popisné
                    </label> 
                </div>

                <div className='relative  w-[230px] m-2'>
                    <input type="text" 
                        id="floating_outlined" 
                        className="block px-2.5 pb-2.5 pt-4 w-full text-sm  rounded border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                        placeholder="Ulice a číslo popisné" 
                        name='city' value={uzivatel.city} onChange={handleInputChange} />
                    <label htmlFor="floating_outlined" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
                    Město
                    </label> 
                </div>

                <div className='relative w-[100px] m-2'>
                    <input type="text" 
                        id="floating_outlined" 
                        className="block px-2.5 pb-2.5 pt-4 w-full text-sm  rounded border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                        placeholder="Ulice a číslo popisné" 
                        name='zip_code' value={uzivatel.zip_code} onChange={handleInputChange}  />
                    <label htmlFor="floating_outlined" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
                    PSČ
                    </label> 
                </div>

                {nastaveni.config['show_promoCode'] === 1 && 
                <div className="clear-both mt-2 ml-2">
                    <VlozitAkcniKod setZprava={setZprava}
                                    scanResult={scanResult}
                                    setZobrazeni={setZobrazeni} />
                </div>
                }
                
                <button type="submit" 
                        className="float-right m-0 text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 shadow-lg shadow-green-500/50 dark:shadow-lg dark:shadow-green-800/80 font-medium rounded-lg text-sm px-1 py-2.5 text-center mb-2"
                >Uložit a pokračovat</button>
            </form>



        </div>  
        
        
    )
}

export default Profil;