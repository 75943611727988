import Reklama from "./Reklama";
import Polozky from "./Polozky";
import Dodavatele from "./Dodavatele";
import Kosik from "./Kosik";
import Filtry from "./Filtry";
import { useEffect, useState } from "react";
import { AuthData } from "../../../auth/AuthWrapper";
import { formatCena, getUrlApi, postData } from "../../../functions";
import Zahlavi from "./Zahlavi";
import DrobeckyNav from "./DrobeckyNav";


function ObjednavkoveListy({nastavitPohled,setAktivniPolozka,aktivniPolozka,setDrobecky,drobecky,zahlaviStatus,setZahlaviStatus}) {
    const [viewDodavatele,setViewDodavatele] = useState('translateX(100%)');
    const [viewKosik,setViewKosik] = useState('translateX(-100%)');
    const {nastaveni,kosik,dodavatel,infoLosy,setInfoLosy} = AuthData();
    const [filtr,setFiltr] = useState('');
    const [sortiment,setSortiment] = useState(nastaveni.sortiment);
    const [data,setData] = useState({});
    

    useEffect(() => {
        document.body.addEventListener('click', function (evt) {
            //console.log('clickBody',document.body.clientWidth, viewDodavatele);
            setViewDodavatele('translateX(100%)');
        });

    },[]);

    useEffect(() => {
        postData(getUrlApi(),{akce: 'odmenyInfo'}).then(result => {
            if (result !== undefined && result.status === 'OK') {
                setData(result.data);
                //console.log('DATA',data);
            }
        });

    },[kosik]);    

    return (<div className="h-screen w-full relative">

                <Zahlavi setZobrazeni={nastavitPohled}
                    odmeny={nastaveni.odmeny} data={data} 
                    zahlaviStatus={zahlaviStatus} />
                <DrobeckyNav  setZobrazeni={nastavitPohled} 
                                    drobecky={drobecky}/>
                <div className='mr-1 h-screen float-left w-[250px] fixed max-md:hidden border-r-[2px] border-gray-300'>
                    <div className='max-h-[calc(100vh-370px)] w-full'>
                        <Reklama dodavatel={dodavatel}/>
                    </div>
                    <div className='w-full h-[320px] absolute bottom-10'>
                        <Kosik kosik={kosik} />
                    </div>
                    <div onClick={(e)=>{nastavitPohled('MOJEOBJEDNAVKA');}} 
                         className='w-[250px] h-10 bg-blue-900 text-center font-bold fixed bottom-0 pt-2 text-white hover:text-white cursor-pointer'>
                        DOKONČIT OBJEDNÁVKU
                    </div>
                </div>
                
                <button type="button" 
                                        className='lg:hidden font-bold float-right me-1 mt-1 py-2 mb-0 text-blue-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-1 focus:ring-gray-200 rounded-sm text-sm px-5 py-1.0 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700'
                                        onClick={(e) => {e.stopPropagation(); setViewDodavatele('translateX(0%)')}}
                                >Dodavatelé</button>                    
 
                <div className='clear-both relative float-right w-[calc(100vw-260px)] max-md:w-full'>

                    <div className='m-0 mt-1 float-left w-[calc(100vw-498px)] max-md:w-full max-sm:pl-0 sm:md:pl-3 max-lg:w-full'>
                                <div className="">
                                    <Filtry setSortiment={setSortiment} 
                                            sortiment={sortiment}
                                            filtr={filtr}
                                            setZobrazeni={nastavitPohled}
                                            setFiltr={setFiltr} />
                                    <div className='clear-both'></div>
                                </div>
                                <div className="">
                                    <Polozky kosik={kosik} 
                                         setAktivniPolozka={setAktivniPolozka}
                                         setZobrazeni={nastavitPohled}
                                         setDrobecky={setDrobecky}
                                         filtr={filtr}
                                         infoLosy={infoLosy}
                                          />    
                                </div>
                        {kosik && kosik.polozky.length > 0 ?  
                            <div className='md:hidden w-full h-12 bg-blue-900  sticky bottom-0 pt-2 text-white'>                            
                                <div className='relative w-full px-1'>
                                    <div className='relative float-left text-[#5cffb3]'>
                                    <button type="button" 
                                        className='lg:hidden float-right m-0 pl-0 pt-1 text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-1 focus:ring-gray-200 rounded-sm text-sm px-2 me-1 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700'
                                        onClick={(e) => {viewKosik === 'translateX(-100%)' ? setViewKosik('translateX(0%)') : setViewKosik('translateX(-100%)')}}>
                                            <svg className="float-left m-0 p-1  w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 21">
                                                <path d="M15 12a1 1 0 0 0 .962-.726l2-7A1 1 0 0 0 17 3H3.77L3.175.745A1 1 0 0 0 2.208 0H1a1 1 0 0 0 0 2h.438l.6 2.255v.019l2 7 .746 2.986A3 3 0 1 0 9 17a2.966 2.966 0 0 0-.184-1h2.368c-.118.32-.18.659-.184 1a3 3 0 1 0 3-3H6.78l-.5-2H15Z"/>
                                            </svg>
                                            <div className='float-right py-1 text-green-500'>
                                                <span className="font-bold">{formatCena(kosik.totalUsetrite)}</span>   
                                            </div>
                                                                             
                                    </button>   
                                        
                                    </div>
                                    <div className='float-right p-0'> 
                                    <button type="button" 
                                        onClick={(e)=>nastavitPohled('MOJEOBJEDNAVKA')}
                                        className='lg:hidden float-right h-[34px] m-0 text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-1 focus:ring-gray-200 rounded-sm text-sm px-1 me-1 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700'>
                                            DOKONČIT OBJEDNÁVKU                                                                             
                                    </button> 


                                    </div>

                                 </div>  
                              </div>
                            : null }  
                            
                           
                    </div>  
                    <div className='max-lg:hidden float-left fixed right-0 w-[230px] m-0 mt-1'>
                        <Dodavatele dodavatele={nastaveni.dodavatele}  
                                    setViewDodavatele={setViewDodavatele}
                                    setFiltr={setFiltr}
                                    sortiment={sortiment}
                                    setInfoLosy={setInfoLosy} />
                    </div>

                    <div style={{transition: 'transform .7s ease',transform: viewDodavatele}} 
                         className={'lg:hidden fixed right-0 top-[56px] w-[225px] ml-2 p-0 z-50 bg-slate-300'}
                    >
                        <Dodavatele dodavatele={nastaveni.dodavatele} 
                                    setViewDodavatele={setViewDodavatele}
                                    setFiltr={setFiltr}
                                    sortiment={sortiment} 
                                    setInfoLosy={setInfoLosy} />
                    </div>

                    <div style={{transition: 'transform .7s ease',transform: viewKosik}} 
                         className={'fixed left-0 top-[56px] w-[250px] p-0 z-50 bg-slate-300 md:hidden'}
                    >
                        <Kosik kosik={kosik} 
                               vse={true} 
                               setViewKosik={setViewKosik}/>
                    </div>
                </div>
            </div>

    )
}

export default ObjednavkoveListy;