import { useEffect, useState } from "react";
import { getUrlApi, imageExists, postData } from "../../../functions";

function Reklama({dodavatel}) {
           var addStyle = {};
           const [zobrazit,setZobrazit] = useState(null);
           var ind = 0;      
           var interv = null;
           var res;
           

           useEffect(() => {
              
              if (dodavatel !== null)  {
                ind = 0; 
                
                postData(getUrlApi(),{akce: 'getReklama',dodavatelId: dodavatel.id}).then(result => {
                    setZobrazit(null);
                    clearInterval(interv);
                    if (result !== undefined && result.status === 'OK') {
                        
                        if (result.obrazky.length === 1) {
                           res = zobrazitReklamu(result.obrazky,ind);
                           setZobrazit(res);
                        }

                        if (result.obrazky.length > 1) {
                            res = zobrazitReklamu(result.obrazky,ind);
                            setZobrazit(res);
                            interv = setInterval(() => {
                                    if ((result.obrazky.length-1) > ind) ind += 1;
                                    else ind = 0;
                                    res = zobrazitReklamu(result.obrazky,ind);
                                    setZobrazit(res);

                                },4000);
                            }
                    }              
                });

              }

              return () => {
                clearInterval(interv);
              }
           },[dodavatel]);
           

           const zobrazitReklamu = (obrazky,index) =>{  
            //console.log('zobrazit reklamu',index);          
                    return <>
                            
                            <div style={{ 'animation': 'fadeIn 5s' }}>
                             <img src={"img/nahledy/"+obrazky[index]} alt='Alimpex' className='w-full mb-1 mt-1 border-0 border-gray-300'  style={addStyle} />
                            </div> 
                            
                           </>  
            };
    

    return (
        <>
            <div className='relative w-full p-1'>
                {zobrazit}
            </div>
        </>
    )

}

export default Reklama;