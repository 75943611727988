import { useEffect, useState } from "react";

import HlavniNabidka from "./HlavniNabidka";
import Zahlavi from "./Zahlavi";
import DrobeckyNav,{nastavitDrobecky} from "./DrobeckyNav";
import Profil from "./Profil";
import MojeObjednavka from "./MojeObjednavka";
import Rekapitulace from "./Rekapitulace";
import OdmenyVyber from "./OdmenyVyber";
import BombaCeny from "./BombaCeny";
import Scanner from "./Scanner";
import ObjednavkoveListy from "./ObjednavkoveListy";
import { AuthData } from "../../../auth/AuthWrapper";
import Oznameni from "./components/Oznameni";
import { getUrlApi, postData } from "../../../functions";
import Mapa from "./Mapa";
import Objednat from "./Objednat";
import ObjednatDodavatel from "./ObjednatDodavatel";

function Nejnakup() {
    const [zobrazeni,setZobrazeni] = useState('PROFIL');
    const {nastaveni,kosik,setInfoLosy} = AuthData();
    const [drobecky,setDrobecky] = useState([]);
    const [aktivniPolozka,setAktivniPolozka] = useState(null);
    const [zprava,setZprava] = useState('');
    const [masterdata,setMasterdata] = useState(null);
    const [scanResult,setScanResult] = useState(null);

    const [zahlaviStatus, setZahlaviStatus] = useState(1);
    const nastavitPohled = (klic) => {
        //console.log('NASTAVIT pohled',klic);
        nastavitDrobecky(klic,setDrobecky);
        setZobrazeni(klic);
    }

    useEffect( () => {
        
        document.addEventListener('touchstart',onTouch);


        window.history.pushState(null, null, window.top.location.pathname + window.top.location.search);
        window.addEventListener('popstate', (e) => {
            e.preventDefault();
            // Insert Your Logic Here, You Can Do Whatever You Want
            window.history.pushState(null, null, window.top.location.pathname + window.top.location.search);
        });
            window.history.back = function() { return false; };

            document.addEventListener("touchstart", function(event) {
                if (event.target.tagName === "A") {
                event.preventDefault();
                }
            });
            
          document.addEventListener("touchmove", function(event) {
            if (event.deltaY > 0) {
              event.preventDefault();
              //console.log9('TOUCHMOVE prevent',event);
            }
          });
          
    },[]);

    useEffect(() => {
        postData(getUrlApi(),{akce: 'odmenyInfo'}).then(result => {
            if (result !== undefined && result.status === 'OK') {
                setMasterdata(result.data);
                //console.log('DATA',result.data);
            }
        });

    },[kosik]);    


    const onTouch = (e) => {
       // e.preventDefault();
        //window.location.replace();
    }

    return (
        <>
        <Oznameni zprava={zprava} setZprava={setZprava} />
        <div className="h-screen w-full relative">

            
        <Objednat polozka={aktivniPolozka} 
                    objednaciDny={nastaveni.weeks} 
                    setAktivniPolozka={setAktivniPolozka}
                    setZahlaviStatus={setZahlaviStatus}
                    setInfoLosy={setInfoLosy} /> 

        {zobrazeni === 'OBJEDNATDODAVATEL' ? 
                <>
                    <Zahlavi setZobrazeni={setZobrazeni}
                        data={masterdata}
                        zahlaviStatus={zahlaviStatus} />
                        <div className='min-h-[calc(100vh-62px)] w-full p-1'>
                        <ObjednatDodavatel  kosik={kosik} 
                                         setAktivniPolozka={setAktivniPolozka}
                                         setZobrazeni={nastavitPohled}
                                         setDrobecky={setDrobecky}
                                          />
                    </div>            
                </>
                : null
            }

            {zobrazeni === 'PROFIL' ? 
                <>
                    <Zahlavi setZobrazeni={setZobrazeni}
                        data={masterdata}
                        zahlaviStatus={zahlaviStatus} />
                        <div className='min-h-[calc(100vh-62px)] w-full p-1'>
                        <Profil setZobrazeni={nastavitPohled} 
                                    setZprava={setZprava} 
                                    scanResult={scanResult}
                                    />
                    </div>            
                </>
                : null
            }
            {zobrazeni === 'BOMBACENY' ? 
                <>
                <Zahlavi setZobrazeni={setZobrazeni}
                        data={masterdata}
                        zahlaviStatus={zahlaviStatus} />
                <div className='relative float-left'>
                    <DrobeckyNav  setZobrazeni={nastavitPohled} 
                                  drobecky={drobecky}/>
                </div>
                <div style={{clear: 'both'}}></div>
                <div className='min-h-[calc(100vh-62px)] w-full'>
                        <BombaCeny setAktivniPolozka={setAktivniPolozka}/>
                </div>            
                </>
                : null
            }    
            {zobrazeni === 'SCANNER' ? 
                <div className='min-h-[calc(100vh-62px)] w-full'>
                        <DrobeckyNav  setZobrazeni={nastavitPohled} 
                                      drobecky={drobecky}/>
                        <Scanner setZobrazeni={nastavitPohled} setScanResult={setScanResult} scanResult={scanResult} />
                </div>            
                : null
            }      
            {zobrazeni === 'MAPA' ? 
            <>
                <Zahlavi setZobrazeni={setZobrazeni}
                        data={masterdata}
                        zahlaviStatus={zahlaviStatus} />
                <div className='min-h-[calc(100vh-62px)] w-full'>
                        <DrobeckyNav  setZobrazeni={nastavitPohled} 
                                      drobecky={drobecky}/>
                        <Mapa />
                </div>     
            </>       
                : null
            }                
            {zobrazeni === 'HLAVNINABIDKA' ? 
                <>
                <Zahlavi setZobrazeni={setZobrazeni}
                        data={masterdata}
                        zahlaviStatus={zahlaviStatus} />
                <div className='min-h-[calc(100vh-62px)] w-full'>
                    <HlavniNabidka  setZobrazeni={nastavitPohled}
                                    setDrobecky={setDrobecky} />
                </div>        
                </>
                : null
            }            
            { zobrazeni === 'OBJEDNAVKOVELISTY' ? 
               <>
                <div className=" mt-0">
                    <ObjednavkoveListy nastavitPohled={nastavitPohled}
                                   setAktivniPolozka={setAktivniPolozka}
                                   aktivniPolozka={aktivniPolozka}
                                   setDrobecky={setDrobecky}
                                   drobecky={drobecky}
                                   zahlaviStatus={zahlaviStatus}
                                   setZahlaviStatus={setZahlaviStatus}
                                    />
                </div>
                
               </> 
               : null 
            }
            {zobrazeni === 'MOJEOBJEDNAVKA' ? 
              <>
              <Zahlavi setZobrazeni={setZobrazeni}
                       data={masterdata}
                       zahlaviStatus={zahlaviStatus} />
              <div className='min-h-[calc(100vh-62px)] w-full relative'>
                <div className='relative float-left'>
                    <DrobeckyNav  setZobrazeni={nastavitPohled}
                    drobecky={drobecky} />
                </div>
                <div style={{clear: 'both'}}></div>
                <div className='h-full w-full mt-5'>
                        <MojeObjednavka setZobrazeni={nastavitPohled} 
                                        objednaciDny={nastaveni.weeks}
                                        setZprava={setZprava} />
                </div>            
              </div>
              </>
                : null
            }
            {zobrazeni === 'REKAPITULACE' ? 
              <>
                <Zahlavi setZobrazeni={setZobrazeni}
                       data={masterdata}
                       zahlaviStatus={zahlaviStatus} />
              <div className='min-h-[calc(100vh-62px)] w-full relative'>
                <div className='relative float-left max-w-full'>
                    <DrobeckyNav  setZobrazeni={nastavitPohled}
                    drobecky={drobecky} />
                </div>
                <div style={{clear: 'both'}}></div>
                <div className='h-full w-full'>
                        <Rekapitulace setZobrazeni={nastavitPohled} 
                                        objednaciDny={nastaveni.weeks}
                                        setZprava={setZprava}/>
                </div>            
              </div>
              </>
                : null
            }            
            {zobrazeni === 'ODMENYVYBER' ? 
                <>
                <Zahlavi setZobrazeni={setZobrazeni}
                          data={masterdata}
                          zahlaviStatus={zahlaviStatus} />
                <div className='min-h-[calc(100vh-62px)] w-full'>
                        <DrobeckyNav  setZobrazeni={nastavitPohled} 
                                      drobecky={drobecky}/>
                        <OdmenyVyber setZobrazeni={nastavitPohled}
                                     setZprava={setZprava} />
                </div>            
                </>
                : null
            }
        </div>
      </>
    )
}

export default Nejnakup;