import { Avatar } from "flowbite-react";
import { AuthData } from "../../../auth/AuthWrapper";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import InfoOdmeny from "./components/InfoOdmeny";

function Zahlavi({setZobrazeni,odmeny,data,zahlaviStatus}) {

    const navigate = useNavigate();
    const {user,logout} = AuthData();
    

    if (zahlaviStatus  === undefined) zahlaviStatus = 1;
    
    //ochrana proti vstupu neprihlasenym
    useEffect(()=>{
        //console.log('USER ',user);
        if (user.user_name === '') {
            //console.log('USERneni ',user);
            navigate('/');
        }    
    },[user]);
   

    return (
        <>
        
          <div className={"w-full h-14 top-0 z-40 fixed "+(zahlaviStatus === 1 ? 'bg-blue-900' : 'bg-red-900')}>
            <div className="relative"
                 onClick={() =>setZobrazeni('HLAVNINABIDKA')}>
                <img src='img/alimpex/Logo_bile.png' className='max-sm:block hidden float-left h-10 m-2' />
                <img src='img/alimpex/Logo_bile.png' className='md:block hidden xl:block float-left h-10 m-2' />
                <img src='img/favicon.png' className='hidden sm:max-md:block float-left h-10 m-2' />
            </div>
            <div className="max-sm:hidden block relative">
              <InfoOdmeny  data={data} />
            </div>
            <div className='relative me-2 w-[150px] float-right '>
            {user.user_name != '' ?
                <>
                <div className='w-full text-right pt-2  text-white text-sm'>
                    <div onClick={(e)=>{setZobrazeni('PROFIL')}} className='block font-bold'>{user.user_name}</div>
                    <div onClick={(e)=>{console.log('ODHLASITSEclick');logout();navigate('/');}} className='hover:underline cursor-pointer'>Odhlásit se</div>
                </div>
                </>
                 : null}
            </div>

          </div>
          <div className="h-[54px]"></div>
          <div className='clear-both'></div>

          <div className="block sm:hidden relative" >
              <InfoOdmeny data={data} />
            </div>
        </>
    )
}

export default Zahlavi;