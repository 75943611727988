import { useState,Fragment, useEffect } from 'react'
import { Dialog,Transition } from '@headlessui/react'

function MyDialog({polozka,akcePotvrdit}) {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() =>{
  if (polozka !== null) setIsOpen(true);
  else setIsOpen(false);
  },[polozka]);


  return (

      <Dialog open={isOpen}
        onClose={() => setIsOpen(false)}
        className="relative z-50 "
      >
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

        {/* Full-screen container to center the panel */}
        <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
          {/* The actual dialog panel  */}
          <Dialog.Panel className="p-2 mx-auto max-w-sm rounded bg-white">
            <Dialog.Title className='text-xl font-bold text-center m-3 border-b'>Odstranění položky z košíku</Dialog.Title>

          <p className='my-4 mx-2'>
            Opravdu si přejete smazat {polozka && polozka.product_name} v celkovém počtu {polozka && polozka.row_qnt} ?
          </p>
          <div className='w-full relative'>
             <button onClick={() => setIsOpen(false)}
                     className='float-left m-1 text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 shadow-lg shadow-green-500/50 dark:shadow-lg dark:shadow-green-800/80 font-medium rounded text-sm px-2.5 py-2.5 text-center mb-2'>Zpět</button>       
             <button onClick={() => {setIsOpen(false);akcePotvrdit(polozka)}}
                    className='float-right m-1 text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-red-800/80 font-medium rounded text-sm px-2.5 py-2.5 text-center mb-2'>Potvrdit</button>
          </div>                    
            </Dialog.Panel>
        </div>
      </Dialog>

  )
}
export default MyDialog;