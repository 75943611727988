import { createContext, useContext, useState } from "react"
import {  RenderRoutes } from "../components/structure/RenderNavigation";
import { getUrlApi, postData,sha1 } from '../functions';

const AuthContext = createContext();
export const AuthData = () => useContext(AuthContext);


export const AuthWrapper = () => {

     const [ user, setUser ] = useState({userNo: '',user_name: "", isAuthenticated: false});

     const [nastaveni,setNastaveni] = useState([]);
     const [kosik,setKosik] = useState([]);
     const [polozky,setPolozky] = useState([]);
     const [dodavatel,setDodavatel] = useState([]);
     const [bombaCeny,setBombaCeny] = useState([]);
     const [categoryID,setCategoryID] = useState(null);
     const [promoCode,setPromoCode] = useState(null);
     const [infoLosy,setInfoLosy] = useState('');

     const login = (userNo, password) => {

          // Make a call to the authentication API to check the username
          
          return new Promise((resolve, reject) => {
               postData(getUrlApi(),{akce: 'login',login:userNo,pass: sha1(password)}).then( (result) => {
                    
              
                    if (result !== null && result.user.user_name !== undefined) {
                         
                         setUser({...result.user, isAuthenticated: true});
                         setNastaveni(result.nastaveni);               
                         setKosik(result.kosik);
                         setDodavatel(result.dodavatel);
                         setPolozky(result.polozky);
                         setBombaCeny(result.bombaCeny);
                         resolve("success")
                    } else {
                         reject("Neplatné přihlášení")
                    }
                    
               });
          })

          
          
     }

     const logout = () => {

         // setNastaveni([]);
          //setKosik([]);
          setPolozky([]);
          //setDodavatel([]);
          setCategoryID(null);
          //setBombaCeny([]);
          setUser({userNo: '',user_name: "", isAuthenticated: false});

     }

     return (
          
               <AuthContext.Provider value={{user, setUser, login, logout, 
                                             nastaveni,setNastaveni, 
                                             kosik, setKosik, 
                                             polozky, setPolozky,
                                             dodavatel, setDodavatel,
                                             bombaCeny,
                                             categoryID,setCategoryID,
                                             infoLosy,setInfoLosy }}>
                    <>
                      
                         <RenderRoutes />
                    </>
                    
               </AuthContext.Provider>
          
     )

}