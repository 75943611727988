import { useEffect, useState } from "react";
import { getUrlApi, postData, vycistitCestinu } from "../../../functions";
import { Badge } from 'flowbite-react';
import { AuthData } from "../../../auth/AuthWrapper";

function Sortiment({sortiment,setSortiment,viewSortiment,setViewSortiment}) {
    const [list,setList] = useState(sortiment);
    const {dodavatel,nastaveni,setNastaveni,setPolozky} = AuthData();
    var addClass ='hidden';
    viewSortiment ? addClass = 'block' : addClass = 'hidden';

    const handleInputChange = (position) => {
        let copyPom = [...list];
        copyPom[position]['checked'] = !copyPom[position]['checked'];

        //console.log('handleInputChange',copyPom ,position);
        setList(copyPom);
      
      
    }
    

    const nacistPolozky = () => {
        postData(getUrlApi(), { akce: 'getProducts',type: 'sortiment',dodavatelId:dodavatel.id,filtr: '',categories: sortiment}).then((result) => {
            if (result !== undefined && result.status === 'OK') {            
              setPolozky(result.polozky);  
              var pom = nastaveni;  
              pom['dodavatele'] = result.dodavatele     
              setNastaveni(nastaveni);      
              //console.log('polozkyData',result);
            }
       }); 

  }


    return (
    <div className={addClass+" backdrop-contrast-50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full  h-[calc(100%)] max-h-full"}>
    <div className="relative top-10 p-4 w-full max-w-2xl max-h-full ml-auto mr-auto mt-10 ">

        <div className="relative bg-white rounded-sm border-2 shadow-xl border-[#858484] dark:bg-gray-600">

            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-sm dark:border-gray-600">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                    Sortiment
                </h3>
                <button type="button" 
                        className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" 
                        onClick={(e) =>setViewSortiment(false)}
                        title='Zavřít nabídku sortimentu'>
                    <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                    </svg>
                </button>
            </div>
         
            <div className="p-4 md:p-5 space-y-4">
                {list !==undefined && list.map((row,index) => {
                                return   <div key={index}  className="flex items-start mb-5"
                                              onClick={() => handleInputChange(index)}>
                                <div className="flex items-center h-5"
                                      >
                                  <input 
                                         type="checkbox" 
                                         checked={row['checked']}
                                         className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800" 
                                     
                                         />
                                </div>
                                <img className="ml-2 h-6 " src={'img/sortiment/category_'+row.id+'.png'} alt={row.name !== null && vycistitCestinu(row.name)} />
                                <label className="float-left ms-2  text-gray-900 dark:text-gray-300">
                                    {row.name}

                                </label>
                                <div className="inline ml-2">
                                        <Badge color="success">{row.pocet}</Badge>
                                </div>
                              </div>
                             
                             })
                             }
            </div>
        
            <div className="p-2 pb-12 border-t border-gray-200 rounded-b dark:border-gray-600">
                <button data-modal-hide="static-modal" 
                        type="button" 
                        onClick={(e) =>setViewSortiment(false)}
                        className="float-left text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                >Zpět</button>
                <button type="button" 
                        className="float-right text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        onClick={(e) => {setSortiment(list);nacistPolozky();setViewSortiment(false)}}
                >Potvrdit</button>
                
            </div>
        </div>
    </div>
</div>
        
    )
}

export default Sortiment;