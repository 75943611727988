import { useEffect, useState } from "react";
import { formatNum, getUrlApi, postData } from "../../../functions";
import BombaCenyVyber from "./BombaCenyVyber";
import { AuthData } from "../../../auth/AuthWrapper";

function BombaCeny({setAktivniPolozka}) {
    const {bombaCeny,categoryID,setCategoryID,kosik} = AuthData();
    const [bombaPol,setBombaPol] = useState([]);
    var vKosikuClass = '';
    var pom = null;
 

    useEffect(() => {
            filtrovatBombaPol();
    },[categoryID]);

    const getSleva = (item) => {

        return Math.round((item.price - item.promo_price)/(item.price)*100) +'%';
    }

    const filtrovatBombaPol = () => {
        //console.log('filtrovat',categoryID,bombaCeny);
        if (bombaCeny !== undefined) setBombaPol(bombaCeny.polozky.filter(item => item.category === categoryID));
    }

    return (<>
              <div className= "text-center font-bold text-2xl m-2">Bomba ceny</div>
              <div className="w-full relative">
                 <BombaCenyVyber onClickAkce={setCategoryID} />
              </div>
                

               
            <div className="max-md:max-w-[400px] md:max-w-[800px] xl:max-w-[1200px] relative p-1 pr-3 mx-auto"> 
              {bombaPol !== undefined ? bombaPol.map((item,index) =>{
                        var vKosikuClass = 'border-gray-200';
                       if (kosik !== undefined && kosik.polozky) {
                        
                        pom = kosik.polozky.filter(pol => pol.product_id === item.id );
                        if (pom.length > 0) pom = pom[0];
                        //console.log('bomba',pom);
                        if (pom.row_qnt !== undefined && pom.row_qnt !== 0) vKosikuClass = 'border-[3px] border-blue-800';
                       }
                       return( <>
                            <div key={item.id} className={vKosikuClass+" float-left h-[195px] m-1 p-1  relative w-[370px] max-md:w-full bg-white border rounded shadow"}
                                 onClick={(e) => {console.log('bombaObjednat',item);setAktivniPolozka(item)}}>
                                
                                {pom.row_qnt !== undefined && pom.row_qnt !== 0 &&
                                    <div className="float-right text-white bg-blue-800 p-2 left-0 top-0 absolute z-20">
                                        {formatNum(pom.row_qnt)+'x'}
                                    </div>
                                } 

                                <div className="p-4 w-[110px] relative max-h-[80px] float-left">
            
                                    <img src={'https://obrazky.alimpexfood.cz/DATA_x220/'+item.product_no+'.png'} 
                                         className='m-auto  max-h-[120px] hover:scale-[1.7] hover:absolute hover:z-30 hover:ml-[2em] hover:opacity-100' alt={''} />
                                    <div className="mt-[-1em] absolute z-10 bg-red-600 rounded-full text-white font-bold h-[50px] w-[50px] left-[80px] p-3 pl-2 text-center opacity-90">
                                        {getSleva(item)}
                                    </div>
                                </div>
                                <div className="p-1 float-right w-[210px]">
                                    <div className="p-0 m-0 pb-3 text-[1.2em] w-full text-center font-normal text-gray-700 dark:text-gray-400">{item.product_name}</div>
                                    
                                    <div className="text-center font-bold">bal {item.pcs} {item.unit}</div>
                        
                                    <div className="text-center font-bold text-gray-600 m-0">{item.price} Kč</div>
                                    <div className="text-center text-red-600 m-2 text-2xl font-bold">{item.promo_price} Kč</div>
                                </div>
                            </div>
                            </>
                        )
                    })
                    : null
            } 
        
           </div> 
            </>
           )
}

export default BombaCeny;