import { formatCena } from "../../../functions";

function Usetrite({kosik}) {

    var usetrite = 0;
    if (kosik.polozky != undefined) kosik.polozky.map((item,index) =>{
        usetrite += item.row_qnt*Number(item.price);
    });

    return (
        <div className='w-full text-xl'>
            <div className="inline">Ušetříte&nbsp;</div>
           <span className="font-bold first-letter:">{formatCena(kosik.totalUsetrite)}</span>
        </div>
    )
}

export default Usetrite;